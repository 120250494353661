import React from "react";
import { Container } from "reactstrap";
import Navbar from "../../components/navigation";
import What from "../../components/what";
import Explore from "../../components/explore";
import Constants from "../../constants";

import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const modifiers = [
  {
    name: "offset",
    enabled: true,
    options: {
      offset: [0, 4],
    },
  },
];

function Trigger({ getTriggerProps, triggerRef }) {
  return (
    <div
      style={{
        color: Constants.fontColor.white,
        position: "absolute",
        right: 15,
        bottom: 15,
        cursor: "pointer",
        width: "50px",
        height: "50px",
        background: Constants.fontColor.lightBlack,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 3px 3px #00000029",
      }}
      {...getTriggerProps({
        ref: triggerRef,
        className: "trigger",
      })}
    >
      <FontAwesomeIcon icon={faQuestion} />
    </div>
  );
}

function Tooltip({
  getTooltipProps,
  getArrowProps,
  tooltipRef,
  arrowRef,
  placement,
}) {
  return (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        style: {
          background: Constants.fontColor.lightBlack,
          borderRadius: "5px",
          width: "420px",
          marginBottom: "6px",
          boxShadow: "0px 3px 3px #00000029",
          paddingTop: "0px",
        },
      })}
    >
      <div
        {...getArrowProps({
          ref: arrowRef,
          "data-placement": placement,
          className: "tooltip-arrow",
          style: {
            color: Constants.fontColor.lightBlack,
          },
        })}
      />
      <What />
    </div>
  );
}

const Index = (props) => {
  // const [servers] = useState(Constants.servers);

  return (
    <div>
      {/* <Navbar selectedTitle="dashboard" />
      <Container fluid className="container-v-padding">
        <Row className="row-h-padding">
          <Col md="9" className={"no-gutters"}>
            {servers.map(
              (label, index) =>
                label !== "master" && <LatestLedger key={index} label={label} />
            )}
          </Col>
          <Col md="3" className={"no-gutters"}>
            <What />
            <Explore />
          </Col>
        </Row>
      </Container> */}

      <Navbar selectedTitle="dashboard" />
      <Container className="container-v-padding" style={{ maxWidth: "500px" }}>
        <Explore />
      </Container>

      <TooltipTrigger
        placement="top-end"
        trigger="click"
        tooltip={Tooltip}
        modifiers={modifiers}
      >
        {Trigger}
      </TooltipTrigger>
    </div>
  );
};

export default Index;
