import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "../../index.scss";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "27px",
    fontFamily: Constants.font.regularFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  errorMessage: {
    color: "#F44336",
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    marginTop: "30px",
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  xdrInput: {
    boxShadow: "1px 1px 3px #00000029",
    padding: "15px 30px 15px 30px",
    borderRadius: "8px",
    margin: "0",
    width: "100%",
    fontFamily: Constants.font.regularFont,
    color: Constants.fontColor.white,
    border: "none",
    fontSize: Constants.fontSize.standard,
    resize: "none",
    background: Constants.fontColor.darkBlack,
    height: "155px",
    "&:focus": {
      outline: "none",
    },
  },
}));

const ImportXdrComp = (props) => {
  const classes = useStyles();
  const { handleSubmit, onSubmit, register, errors, title, label } = props;

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>{title}</Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ paddingTop: "20px" }}
          >
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label}>{label}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <form
                autoComplete="off"
                onChange={handleSubmit(onSubmit)}
                style={{ textAlign: "right" }}
              >
                <textarea
                  className={classes.xdrInput}
                  type="text"
                  name="xdrInput"
                  ref={register({
                    required: true,
                  })}
                />
                <div style={{ paddingTop: "15px" }}>
                  {errors?.xdrInput?.type === "required" && (
                    <Typography
                      className={classes.errorMessage}
                      component={"span"}
                      style={{ paddingRight: "15px" }}
                    >
                      Can&apos;t be empty
                    </Typography>
                  )}

                  {errors?.xdrInput?.type === "invalidXdr" && (
                    <Typography
                      className={classes.errorMessage}
                      component={"span"}
                      style={{ paddingRight: "15px" }}
                    >
                      XDR is invalid.
                    </Typography>
                  )}
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default ImportXdrComp;
