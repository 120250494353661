import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Button from "@material-ui/core/Button";
import Constants from "../../constants";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "0",
    fontFamily: Constants.font.regularFont,
    wordBreak: "break-all",
  },
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "25px 0 25px 0",
    fontFamily: Constants.font.lightFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  xdrForViewerStyle: {
    padding: "20px 30px",
    borderRadius: "6px",
    background: Constants.fontColor.darkBlack,
    marginTop: "50px",
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
}));

const TransactionOverviewComp = (props) => {
  const classes = useStyles();
  const {
    xdrForViewer,
    submitToNetwork,
    loading,
    setActiveStep,
    scrollToBottom,
  } = props;
  const history = useHistory();
  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "33px" }}>
          <Typography className={classes.mainText} style={{ color: "#75DAAD" }}>
            Transaction Signed!
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <div className={classes.xdrForViewerStyle}>
            <Typography className={classes.mainText}>{xdrForViewer}</Typography>
          </div>

          <div style={{ textAlign: "right", paddingTop: "25px" }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                submitToNetwork();
                setActiveStep(5);
                scrollToBottom();
              }}
              style={{ marginRight: "20px" }}
            >
              {loading ? (
                <CircularProgress style={{ color: "#FFF" }} size={30} />
              ) : (
                "Submit To SSN Network"
              )}
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              onClick={() => history.push("/xdr-viewer")}
            >
              View in XDR Viewer
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default TransactionOverviewComp;
