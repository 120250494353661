import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ServerLabel from "../utilities/serverLabel";
import CardLabel from "../utilities/cardLabel";
import Container from "@material-ui/core/Container";
import Navbar from "../navigation";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label } = useParams();

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" style={{ paddingTop: 16 }}>
        <Row>
          <Col md="12" className={"no-gutters"}>
            <Card body className="card-v-padding-16">
              <ServerLabel label={label} />
              <Row>
                <Col md="12" className={"no-gutters"}>
                  <CardLabel title={translate("Not Found")} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
