import React, { useEffect } from "react";
import { Card, Table, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_SIGNERS } from "../../graphql/query-mutation/ledger";
import InfoAction from "../../components/utilities/infoAction";
import EnvironmentLabel from "../../graphql/client";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();

  function HeadComp() {
    return (
      <tr>
        <th className="text-title-dark">{translate("Signers")}</th>
        <th className="text-title-dark">{translate("Weight")}</th>
      </tr>
    );
  }

  function RowComp({ item }) {
    return (
      <tr>
        <td className="text-para-white">{item.key}</td>
        <td className="text-para-white">{item.weight}</td>
      </tr>
    );
  }

  function MainComp({ data }) {
    return (
      <Card body className="card-v-padding-8">
        <Row>
          <Col md="7" className={"no-gutters"}>
            <Row className="row-h-padding">
              <Table hover borderless responsive>
                <thead>
                  <HeadComp />
                </thead>
                <tbody>
                  {data.map((item, key) => (
                    <RowComp key={key} item={item} />
                  ))}
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_SIGNERS,
      options
    );
    const object = data?.account?.signers;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getData} />;
    }
    if (!object) {
      return <InfoAction description={translate("No Signing Information")} />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
