import React, { useRef } from "react";
import { TextField, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "#1E1E26",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    borderRadius: "6px",
  },
  content: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
  },
  textInput: {
    color: "white",
  },
  error: {
    color: "#e53935",
    fontSize: 16,
  },
}));

const TextFormField = (props) => {
  const classes = useStyles();
  const {
    placeholder,
    name,
    type,
    value,
    handleChange,
    errText,
    maxLength,
  } = props;

  const nameRef = useRef();

  return (
    <div className={classes.content}>
      <TextField
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        className={classes.textField}
        onChange={handleChange}
        inputRef={nameRef}
        InputProps={{
          disableUnderline: true,
          className: classes.textInput,
        }}
        inputProps={{ maxLength: maxLength ? 12 : 10000 }}
      />
      {errText !== "" && (
        <Typography className={classes.error}>{errText}</Typography>
      )}
    </div>
  );
};

TextFormField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.node,
  handleChange: PropTypes.func,
  disableUnderline: PropTypes.bool,
  errText: PropTypes.string,
  maxLength: PropTypes.bool,
};

export default TextFormField;
