import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { NavLink as RouterNaveLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/ssn-logo.png";

const Index = ({ selectedTitle = "", route = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const className = (title) => {
    return title === selectedTitle ? "nav-link-active" : "nav-link-inactive";
  };
  const { t: translate } = useTranslation();

  return (
    <Navbar light sticky="top" expand="md" className="nav">
      <NavbarBrand href="/">
        <img src={Logo} alt="SSN Explorer" height={56} />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar></Nav>
        <Nav navbar>
          <NavItem>
            <NavLink
              className={className("dashboard")}
              to="/"
              tag={RouterNaveLink}
            >
              {translate("Explorer")}
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={className("learn")}
              to="/learn"
              tag={RouterNaveLink}
            >
              {translate("Learn")}
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={className("tools")}
              to="/tools"
              tag={RouterNaveLink}
            >
              {translate("Tools")}
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={className("status")}
              to="/status"
              tag={RouterNaveLink}
            >
              {translate("Status")}
            </NavLink>
          </NavItem> */}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Index;
