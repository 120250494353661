import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    padding: "0px 50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
}));
const MainButton = (props) => {
  const { text, handleClickButton } = props;
  const classes = useStyles();

  return (
    <Button
      color="primary"
      variant="contained"
      className={classes.btn}
      onClick={handleClickButton}
    >
      {text}
    </Button>
  );
};

MainButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  handleClickButton: PropTypes.func,
};

export default MainButton;
