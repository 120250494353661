import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Constants from "../../constants";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "0",
    fontFamily: Constants.font.regularFont,
    wordBreak: "break-all",
  },
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "0 0 0 0",
    fontFamily: Constants.font.lightFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  xdrForViewerStyle: {
    padding: "20px 30px",
    borderRadius: "6px",
    background: Constants.fontColor.darkBlack,
    marginTop: "50px",
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  innerContainerBorderless: {
    padding: "18px",
    borderRadius: "6px",
    marginTop: "40px",
  },
  textfieldStyle: {
    border: `1px solid ${Constants.fontColor.blue}`,
    marginBottom: "12px",
    borderRadius: "5px",
    backgroundColor: "#1E1E26",
  },
}));

const SubmitToNetworkResult = (props) => {
  const classes = useStyles();
  const { submitToNetworkResult } = props;
  return (
    <div style={{ paddingBottom: "60px" }}>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "33px" }}>
          <Typography
            className={classes.mainText}
            style={{ display: "flex", alignContent: "center" }}
          >
            {submitToNetworkResult.hash && submitToNetworkResult.ledger ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  color: Constants.fontColor.success,
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  color: Constants.fontColor.failure,
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />
            )}

            <span
              style={{
                color: submitToNetworkResult.transaction
                  ? Constants.fontColor.failure
                  : Constants.fontColor.success,
              }}
            >
              Network Submission
            </span>
          </Typography>
          <hr
            style={{
              border: "1px solid #1E1F2C",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />
          <div className={classes.innerContainerBorderless}>
            <div style={{ padding: "18px", borderRadius: "6px" }}>
              <Grid container className={classes.root} spacing={4}>
                {submitToNetworkResult.hash && (
                  <Grid item xs={12} sm={3}>
                    <Typography className={classes.label}>Hash</Typography>
                  </Grid>
                )}

                {submitToNetworkResult.hash && (
                  <Grid item xs={12} sm={9} className={classes.textfieldStyle}>
                    <Typography
                      className={classes.subText}
                      style={{ wordBreak: "break-all" }}
                    >
                      {submitToNetworkResult.hash}
                    </Typography>
                  </Grid>
                )}

                {submitToNetworkResult.ledger && (
                  <Grid item xs={12} sm={3}>
                    <Typography className={classes.label}>Ledger</Typography>
                  </Grid>
                )}

                {submitToNetworkResult.ledger && (
                  <Grid item xs={12} sm={9} className={classes.textfieldStyle}>
                    <Typography className={classes.subText}>
                      {submitToNetworkResult.ledger}
                    </Typography>
                  </Grid>
                )}

                {submitToNetworkResult.transaction && (
                  <Grid item xs={12} sm={3}>
                    <Typography className={classes.label}>Error</Typography>
                  </Grid>
                )}

                {submitToNetworkResult.transaction && (
                  <Grid item xs={12} sm={9}>
                    <Typography className={classes.subText}>
                      {submitToNetworkResult.transaction}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SubmitToNetworkResult;
