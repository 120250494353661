import React, { useState } from "react";
import Navbar from "../../components/navigation";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Constants from "../../constants";

import ImportXdrViewerComp from "../../components/xdr-viewer/importXdrViewerComp";
import TransactionOverviewComp from "../../components/sign-transaction/transactionOverviewComp";

import { useForm } from "react-hook-form";

const StellarSdk = require("stellar-sdk");

const useStyles = makeStyles((theme) => ({
  breadcrumbMain: {
    color: Constants.fontColor.white,
    display: "inline-block",
    fontFamily: Constants.font.semiBoldFont,
  },
}));

const Index = () => {
  const classes = useStyles();

  const [transactionOverview, setTransactionOverview] = useState();
  const [signature, setSignature] = useState([]);
  const { register, handleSubmit, errors, setError } = useForm();

  // Get the informations from XDR
  const getXdrTransactionOverview = (XdrBase64TxString, networkPassphrase) => {
    const transaction = StellarSdk.TransactionBuilder.fromXDR(
      XdrBase64TxString,
      networkPassphrase
    );
    return transaction;
  };

  const onSubmit = (formData) => {
    const signatureArray = [];
    if (formData.xdrInput) {
      try {
        Promise.resolve(
          getXdrTransactionOverview(formData.xdrInput, "ssn_production_network")
        ).then((data) => {
          setTransactionOverview(data);
          // This will decodes the Uint8Array Signature to String
          for (let i = 0; i < data?._signatures.length; i++) {
            signatureArray.push("Signature");
            signatureArray.push(
              btoa(
                String.fromCharCode(
                  ...new Uint8Array(data._signatures[i]._attributes.signature)
                )
              )
            );
            console.log(signatureArray);
          }
          setSignature(signatureArray);
          console.log(data);
        });
      } catch (error) {
        console.log("error:");
        setError("xdrInput", {
          type: "invalidXdr",
        });
      }
    }
  };

  return (
    <div>
      <Navbar selectedTitle="tools" />
      <Container maxWidth="xl">
        <Box component="div" style={{ marginTop: "30px" }}>
          <Typography
            className={classes.breadcrumbMain}
            style={{
              fontSize: Constants.fontSize.mainTitle,
            }}
          >
            XDR Viewer
          </Typography>
        </Box>

        <ImportXdrViewerComp
          title={"View the contents of an XDR envelope"}
          label={"Input XDR"}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          errors={errors}
        />

        {transactionOverview && (
          <TransactionOverviewComp
            transactionOverview={transactionOverview}
            title={"Transaction Envelope"}
            displayTopData={true}
            displaySignature={true}
            signature={signature}
            enableMarginTop={true}
          />
        )}
      </Container>
    </div>
  );
};

export default Index;
