import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  QUERY_ACCOUNT_TRANSACTIONS,
  QUERY_LEDGER,
  QUERY_ACCOUNT_REGISTRATION_NAME,
} from "../../graphql/query-mutation/ledger";
import Constants from "../../constants";
import "../../index.scss";
import EnvironmentLabel from "../../graphql/client";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimes } from "@fortawesome/pro-regular-svg-icons";

let ledgerAndDate = [];
const useStyles = makeStyles((theme) => ({
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  containerKeypairs: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  table: {
    "& .MuiTable-root": {
      borderCollapse: "separate !important",
      borderSpacing: "0 5px !important",
    },
    "& .MuiTableRow-root .MuiTableCell-root": {
      borderBottom: "none !important",
    },
    "& .MuiTableCell-root": {
      fontSize: Constants.fontSize.standard,
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  tableDataRoot: {
    "& .tableData": {
      borderBottom: "1px solid #767676",
      padding: "7px 7px 19px 15px",
      fontSize: "14px",
    },

    "&:last-child .tableData": {
      borderBottom: "none",
    },
  },
  dateButton: {
    color: "#FFF",
    background: "#1E1E26",
    padding: "14px",
    "&:hover": {
      backgroundColor: "#1E1E26",
    },
    marginRight: "8px",
  },
  txHashStyle: {
    fontFamily: Constants.font.regularFont,
    color: "#147efb",
    "&:hover ": {
      textDecoration: "underline",
    },
  },
}));

const Index = () => {
  const { label, key } = useParams(); // Enviroment and public key
  const classes = useStyles();
  const client = EnvironmentLabel.getClient(label);
  const history = useHistory();

  const [showArrays, setShowArrays] = useState(null);

  // First query
  const options = {
    client: client,
    variables: { pubKey: key },
    fetchPolicy: "network-only",
  };
  const [getData, { data }] = useLazyQuery(QUERY_ACCOUNT_TRANSACTIONS, options);

  useEffect(() => {
    getData();
  }, []);

  const object = data?.account?.transactions;

  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: moment()._d,
      key: "selection",
    },
  ]);

  const readDateFromLedger = (data) => {
    ledgerAndDate = [...ledgerAndDate, data];
  };

  const confirmFilter = (dateStart, dateEnd) => {
    let tmp = [];
    ledgerAndDate.map((item, index) => {
      const formattedDate = moment(item.closedAt).format("YYYY-MM-DD");
      if (
        moment(formattedDate).isBetween(
          moment(dateStart).format("YYYY-MM-DD"),
          moment(dateEnd).format("YYYY-MM-DD"),
          null,
          []
        )
      ) {
        tmp = [...tmp, item];
      }
    });
    setShowArrays(tmp);
  };

  const checkShowArrays = (idx) => {
    if (showArrays) {
      let check = false;
      showArrays.map((item, index) => {
        if (item.txIndex === idx) {
          check = true;
        }
      });
      return check;
    } else {
      return true;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ background: "#282832", padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className={classes.dateButton}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {showArrays !== null && (
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={faTimes}
              onClick={(e) => {
                e.stopPropagation();
                setShowArrays(null);
              }}
              size={"lg"}
            />
          )}

          {showArrays === null
            ? "All Time"
            : `${moment(dateFilter[0].startDate).format(
                "MMM DD,YYYY"
              )} - ${moment(dateFilter[0].endDate).format("MMM DD,YYYY")}`}
          <FontAwesomeIcon
            style={{ marginLeft: "10px" }}
            icon={faCalendarAlt}
          />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <DateRange
            showDateDisplay={false}
            editableDateInputs={false}
            onChange={(item) => {
              setDateFilter([item.selection]);
              confirmFilter(item.selection.startDate, item.selection.endDate);
            }}
            moveRangeOnFirstSelection={false}
            showSelectionPreview={true}
            ranges={dateFilter}
          />
        </Popover>
      </div>

      <TableContainer className={classes.table}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontFamily: Constants.font.semiBoldFont }}>
                <span style={{ color: Constants.fontColor.gray }}>
                  {" "}
                  Transaction Hash{" "}
                </span>
              </TableCell>
              <TableCell style={{ fontFamily: Constants.font.semiBoldFont }}>
                <span style={{ color: Constants.fontColor.gray }}> From </span>
              </TableCell>
              <TableCell style={{ fontFamily: Constants.font.semiBoldFont }}>
                <span style={{ color: Constants.fontColor.gray }}> To </span>
              </TableCell>
              <TableCell style={{ fontFamily: Constants.font.semiBoldFont }}>
                <span style={{ color: Constants.fontColor.gray }}>
                  {" "}
                  Amount{" "}
                </span>
              </TableCell>
              <TableCell style={{ fontFamily: Constants.font.semiBoldFont }}>
                <span style={{ color: Constants.fontColor.gray }}> Date </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {object?.map((transaction, txIndex) =>
              transaction.operations.map(
                (operation, opIndex) =>
                  // This condition will show only payment and the real payment only not the payment to Sabay as fee
                  operation.type == 1 &&
                  operation.applicationOrder === 1 && (
                    <>
                      {checkShowArrays(txIndex) && (
                        <TableRow key={opIndex}>
                          <TableCell
                            style={{ marginRight: 0, padding: "0" }}
                            onClick={() =>
                              history.push(
                                `/payments/${label}/${transaction.transactionHash}`
                              )
                            }
                          >
                            <span className={classes.txHashStyle}>
                              {transaction.transactionHash}
                            </span>
                          </TableCell>
                          <TableCell style={{ margin: 0, padding: 0 }}>
                            <ShowAccountDetail
                              publicKey={JSON.parse(operation.details).from}
                              label={label}
                            />
                          </TableCell>
                          <TableCell style={{ margin: 0, padding: 0 }}>
                            <ShowAccountDetail
                              publicKey={JSON.parse(operation.details).to}
                              label={label}
                            />
                          </TableCell>

                          <TableCell style={{ margin: 0, padding: 0 }}>
                            <p>
                              <span
                                style={{ fontFamily: Constants.font.lightFont }}
                              >
                                {JSON.parse(operation.details).amount
                                  ? JSON.parse(operation.details).amount +
                                    " " +
                                    JSON.parse(operation.details).asset_code
                                  : "None"}
                              </span>
                            </p>
                          </TableCell>

                          <TableCell style={{ margin: 0, padding: 0 }}>
                            <ShowDate
                              key={transaction.ledgerSequence}
                              txIndex={txIndex}
                              label={label}
                              l={transaction.ledgerSequence}
                              readDateFromLedger={readDateFromLedger}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Index;

const ShowDate = ({ txIndex, l, label, readDateFromLedger }) => {
  const client = EnvironmentLabel.getClient(label);
  // Second query to get the date from Ledger (Closed Date)
  const optionsForLedger = {
    client: client,
    fetchPolicy: "network-only",
  };
  const [getLedger, { data: dataLedger }] = useLazyQuery(
    QUERY_LEDGER,
    optionsForLedger
  );
  useEffect(() => {
    getLedger({
      variables: {
        number: l,
      },
    });
  }, []);
  useEffect(() => {
    if (dataLedger) {
      const tmp = {
        txIndex,
        ledger: l,
        closedAt: dataLedger?.ledger[0]?.closedAt
          ? dataLedger?.ledger[0]?.closedAt
          : null,
      };
      readDateFromLedger(tmp);
    }
  }, [dataLedger]);

  const dateUTC = dataLedger?.ledger[0]?.closedAt;
  const date = moment.utc(dateUTC).format("YYYY-MM-DD hh:mm:ss A");
  const stillUtc = moment.utc(date).toDate();
  const localTime = moment(stillUtc).local().format("YYYY-MM-DD hh:mm:ss A");

  return (
    <p style={{ fontFamily: Constants.font.lightFont }}>
      {localTime ? localTime : "None"}
    </p>
  );
};

const ShowAccountDetail = ({ publicKey, label }) => {
  const client = EnvironmentLabel.getClient(label);
  // Third query to get readable text
  const optionsForAccountDetail = {
    client: client,
    fetchPolicy: "network-only",
  };
  const [getLedger, { data: dataAccountDetail }] = useLazyQuery(
    QUERY_ACCOUNT_REGISTRATION_NAME,
    optionsForAccountDetail
  );

  useEffect(() => {
    getLedger({
      variables: {
        pubKey: publicKey,
      },
    });
  }, []);

  return (
    <p>
      <span style={{ fontFamily: Constants.font.lightFont }}>
        {dataAccountDetail?.account?.data
          ? dataAccountDetail?.account?.data[0].value +
            " " +
            "(" +
            String(publicKey).substring(0, 5) +
            ")"
          : "None"}
      </span>
    </p>
  );
};
