import React from "react";
import {
  Typography,
  makeStyles,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import TextFormField from "../../../components/textfield/textfield";
import PropTypes from "prop-types";
import Constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  gridTextCenter: {
    height: "52px",
    marginTop: "16px",
    alignItems: "center",
  },
  defaultPadding: {
    padding: "10px 15px",
  },
  border: {
    borderTop: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    padding: "10px 15px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    textTransform: "Capitalize",
  },
  borderRight: {
    borderRight: "2px solid #272831",
    backgroundColor: "red",
  },
  rootToggleButton: {
    "& .MuiToggleButton-root": {
      color: Constants.fontColor.white,
      background: Constants.fontColor.darkBlack,
      fontFamily: Constants.font.lightFont,
      padding: "15px 20px 15px 20px",
    },
    "& .Mui-selected": {
      background: Constants.fontColor.blue,
    },
    "& .Mui-selected:hover": {
      background: Constants.fontColor.blue,
    },
  },
}));

const CustomRadio = withStyles({
  root: {
    color: Constants.fontColor.gray,
    "&$checked": {
      color: Constants.fontColor.gray,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const Index = (props) => {
  const classes = useStyles();
  const {
    changeTrust,
    handleChangeTrustTextChange,
    handleChangeAssetIssuerTextChange,
    index,
    handleAssetChange,
  } = props;

  return (
    <Grid container className={`${classes.defaultPadding} ${classes.border}`}>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.itemCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Asset Code</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={10}
        lg={10}
        style={{ padding: "0px 15px" }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="asset"
            name="assetCode"
            value={changeTrust.asset.code}
            onChange={handleAssetChange(index)}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="KHR"
              control={<CustomRadio />}
              label={<Typography className={classes.text}>KHR</Typography>}
            />
            <FormControlLabel
              value="USD"
              control={<CustomRadio />}
              label={<Typography className={classes.text}>USD</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Issuer Account ID</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          name="issuer"
          value={changeTrust.asset.issuer}
          type="text"
          handleChange={handleChangeAssetIssuerTextChange(index)}
          disableUnderline={true}
          maxLength={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Trust Limit (Optional)</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          name="limit"
          value={changeTrust.limit}
          type="text"
          handleChange={handleChangeTrustTextChange(index)}
          disableUnderline={true}
          maxLength={true}
        />
      </Grid>
    </Grid>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  changeTrust: PropTypes.object,
  handleChangeTrustTextChange: PropTypes.func,
  handleAssetChange: PropTypes.func,
  handleChangeAssetIssuerTextChange: PropTypes.func,
  index: PropTypes.number,
};

export default Index;
