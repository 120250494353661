import React from "react";
import { Card, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import ServerLabel from "../utilities/serverLabel";
import CardLabel from "../utilities/cardLabel";
import moment from "moment";

const Index = ({ tran = null }) => {
  const { t: translate } = useTranslation();
  const { label } = useParams();
  const history = useHistory();

  const dateUTC = tran.createdAt;
  const date = moment.utc(dateUTC).format("YYYY-MM-DD hh:mm:ss A");
  const stillUtc = moment.utc(date).toDate();
  const localTime = moment(stillUtc).local().format("YYYY-MM-DD hh:mm:ss A");

  function RowComp({ title, data }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-white">{data}</td>
      </tr>
    );
  }

  function RowClickComp({ title, data, onClick = () => null }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-link" onClick={onClick}>
          {data}
        </td>
      </tr>
    );
  }

  return (
    <div className="card-v-padding-16">
      <Card body>
        <ServerLabel label={label} />
        <CardLabel title={translate("Transaction Information")} />
        <Table hover borderless responsive className="margin-top-16">
          <tbody>
            <RowClickComp
              title={translate("Transaction Hash")}
              data={tran.transactionHash}
              onClick={() =>
                history.push(`/transaction/${label}/${tran.transactionHash}`)
              }
            />
            <RowClickComp
              title={translate("Source Account")}
              data={tran.account}
              onClick={() => history.push(`/account/${label}/${tran.account}`)}
            />
            <RowClickComp
              title={translate("Ledger")}
              data={tran.ledgerSequence}
              onClick={() =>
                history.push(`/ledger/${label}/${tran.ledgerSequence}`)
              }
            />
            <RowComp title={translate("Fee")} data={tran.maxFee} />
            <RowComp title={translate("Memo")} data={tran.memo} />
            <RowComp title={translate("Time")} data={localTime} />
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Index;
