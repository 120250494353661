import React from "react";
import { Snackbar } from "@material-ui/core";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import Constants from "../../../constants";

const Index = (props) => {
  const { open, text, handleCloseSnackBar } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleCloseSnackBar}
    >
      <Alert
        variant="filled"
        severity="success"
        style={{ backgroundColor: Constants.fontColor.darkBlack }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  text: PropTypes.string,
  handleCloseSnackBar: PropTypes.func,
};

export default Index;
