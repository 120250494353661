import React from "react";
import Navbar from "../../components/navigation";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import "../../index.scss";
import Grid from "@material-ui/core/Grid";
import Constants from "../../constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faShapes,
  faEye,
  faUserCog,
  faHandshake,
} from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleMain: {
    color: Constants.fontColor.white,
    display: "block",
    fontFamily: Constants.font.semiBoldFont,
    fontSize: Constants.fontSize.mainTitle,
    paddingRight: "25px",
    paddingBottom: "20px",
  },
  titleSub: {
    color: Constants.fontColor.white,
    display: "block",
    fontFamily: Constants.font.regularFont,
    fontSize: Constants.fontSize.titleContainer,
    paddingRight: "25px",
  },
  container: {
    marginTop: "30px",
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#30303b",
      transition: "0.28s",
    },
  },
  titleInCard: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    padding: "30px 0 15px 0",
    fontFamily: Constants.font.regularFont,
  },
  description: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "0",
    fontFamily: Constants.font.lightFont,
  },
}));

const Index = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Navbar selectedTitle="tools" />

      <Container maxWidth="xl">
        <Box component="div" style={{ marginTop: "30px" }}>
          <Typography className={classes.titleMain}>Tools</Typography>

          <Typography className={classes.titleSub}>
            These tools are here to help you start using SSN in just a few
            minutes. You can create an SSN account, generate keypairs, build and
            sign transactions using these tools.
          </Typography>
        </Box>

        <Grid container className={classes.root} spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component="div"
              className={classes.container}
              onClick={() => history.push("/keypair-generator")}
            >
              <div style={{ padding: "41px" }}>
                <FontAwesomeIcon
                  icon={faKey}
                  style={{ fontSize: "50px", color: "white" }}
                />
                <Typography className={classes.titleInCard}>
                  Keypair Generator
                </Typography>
                <Typography className={classes.description}>
                  Generate keypairs to be used on SSN. You will need your key to
                  sign any transactions on the network.
                </Typography>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component="div"
              className={classes.container}
              onClick={() => history.push("/create-ssn-account")}
            >
              <div style={{ padding: "41px" }}>
                <FontAwesomeIcon
                  icon={faUserCog}
                  style={{ fontSize: "50px", color: "white" }}
                />
                <Typography className={classes.titleInCard}>
                  Create an SSN Account
                </Typography>
                <Typography className={classes.description}>
                  Create and fund your SSN account in just a few clicks.
                </Typography>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component="div"
              className={classes.container}
              onClick={() => history.push("/build-transaction")}
            >
              <div style={{ padding: "41px" }}>
                <FontAwesomeIcon
                  icon={faShapes}
                  style={{ fontSize: "50px", color: "white" }}
                />
                <Typography className={classes.titleInCard}>
                  Transaction Builder
                </Typography>
                <Typography className={classes.description}>
                  Build your transaction with multiple operations from making
                  payment to account configurations
                </Typography>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component="div"
              className={classes.container}
              onClick={() => history.push("/transaction-signer")}
            >
              <div style={{ padding: "41px" }}>
                <FontAwesomeIcon
                  icon={faHandshake}
                  style={{ fontSize: "50px", color: "white" }}
                />
                <Typography className={classes.titleInCard}>
                  Transaction Signer
                </Typography>
                <Typography className={classes.description}>
                  Import and sign transaction envelope.
                </Typography>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              component="div"
              className={classes.container}
              onClick={() => history.push("/xdr-viewer")}
            >
              <div style={{ padding: "41px" }}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ fontSize: "50px", color: "white" }}
                />
                <Typography className={classes.titleInCard}>
                  XDR Viewer
                </Typography>
                <Typography className={classes.description}>
                  View the contents of an XDR envelope
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Index;
