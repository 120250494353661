import React, { useEffect } from "react";
import { Card, Table, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_FLAGS } from "../../graphql/query-mutation/ledger";
import InfoAction from "../utilities/infoAction";
import EnvironmentLabel from "../../graphql/client";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();

  function HeadComp() {
    return (
      <tr>
        <th className="text-title-dark">{translate("Flags")}</th>
        <th className="text-title-dark">{translate("Setting")}</th>
      </tr>
    );
  }

  function RowComp({ title, data }) {
    function flagIcon() {
      if (true === data) {
        return faCheckCircle;
      } else {
        return faTimesCircle;
      }
    }

    function flagClass() {
      if (true === data) {
        return "color-green";
      } else {
        return "color-red";
      }
    }

    return (
      <tr>
        <td className="text-para-white">{title}</td>
        <td className="text-para-white">
          <FontAwesomeIcon
            icon={flagIcon()}
            className={flagClass()}
            size="lg"
          />
        </td>
      </tr>
    );
  }

  function MainComp({ data }) {
    return (
      <div>
        <Card body>
          <Row>
            <Col md="4" className={"no-gutters"}>
              <Table hover borderless responsive>
                <thead>
                  <HeadComp />
                </thead>
                <tbody>
                  <RowComp
                    title={translate("Authorization Immutable")}
                    data={data.authImmutable}
                  />
                  <RowComp
                    title={translate("Authorization Required")}
                    data={data.authRequired}
                  />
                  <RowComp
                    title={translate("Authorization Revocable")}
                    data={data.authRevocable}
                  />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_FLAGS,
      options
    );
    const object = data?.account?.flags;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getData} />;
    }
    if (!object) {
      return <InfoAction description={translate("No Flag Information")} />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
