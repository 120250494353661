import React from "react";
import { Card, Table } from "reactstrap";
import { useTranslation } from "react-i18next";

const Index = ({ ledger = null, ...rest }) => {
  const { t: translate } = useTranslation();

  function TableRow({ title, data }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-white">{data}</td>
      </tr>
    );
  }

  return (
    <div className="card-v-padding-16">
      <Card body>
        <Table hover borderless responsive>
          <tbody>
            <TableRow title={translate("Base Free")} data={ledger.baseFee} />
            <TableRow
              title={translate("Base Reserve")}
              data={ledger.baseReserve}
            />
            <TableRow
              title={translate("Protocol Version")}
              data={ledger.protocolVersion}
            />
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Index;
