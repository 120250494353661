import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "../../index.scss";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Container from "@material-ui/core/Container";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $notchedOutline": {
      borderColor: Constants.fontColor.white,
    },
    "&$focused $notchedOutline": {
      borderColor: Constants.fontColor.white,
    },
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  rootTextField: {
    margin: "7px 0px",
    "& .MuiInputBase-input": {
      color: Constants.fontColor.white,
    },
    "& .MuiTypography-colorTextSecondary": {
      color: Constants.fontColor.gray,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF",
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
    margin: "12px 0 12px 12px",
  },

  focused: {},
  notchedOutline: {},
}));

const TransactionOverviewComp = (props) => {
  const classes = useStyles();

  const {
    onSubmit,
    handleSubmit,
    register,
    errors,
    setDisplayPage1,
    setDisplayPage2,
    setActiveStep,
    setUserEnterSecretWords,
    scrollToBottom,
  } = props;

  console.log(errors);
  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>
            Please enter the Secret Words in the fields below
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <Container maxWidth="xl" style={{ padding: 0, marginTop: "20px" }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword1"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">1</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword2"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">2</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword3"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">3</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword4"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">4</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword5"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">5</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword6"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">6</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword7"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">7</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword8"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">8</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword9"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">9</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword10"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">10</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword11"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">11</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    className={classes.rootTextField}
                    inputRef={register({
                      required: true,
                    })}
                    name="secretword12"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">12</InputAdornment>
                      ),
                      classes: {
                        root: classes.root,
                        notchedOutline: classes.notchedOutline,
                        focused: classes.focused,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <div style={{ textAlign: "right", marginTop: "10px" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: Constants.fontColor.gray }}
                  onClick={() => {
                    setDisplayPage1(true);
                    setDisplayPage2(false);
                    setActiveStep(2);
                    setUserEnterSecretWords("");
                  }}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  onClick={() => scrollToBottom()}
                >
                  Next
                </Button>
              </div>
            </form>
          </Container>
        </div>
      </Box>
    </div>
  );
};

export default TransactionOverviewComp;
