import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import TextFormField from "../../../components/textfield/textfield";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  gridTextCenter: {
    height: "52px",
    marginTop: "16px",
    alignItems: "center",
  },
  defaultPadding: {
    padding: "10px 15px",
  },
  border: {
    borderTop: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    padding: "10px 15px",
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { payment, handlePayment, destinationErrMsg, index } = props;

  return (
    <Grid container className={`${classes.defaultPadding} ${classes.border}`}>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Destination</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          placeholder="Example: GATYCTIG2VZI2537PLD43QOO3ZBWIOLDT5JPWYPFQRJJFTMPUQKQ7NJED43QOO3ZBWIOLDT5JPWYPFQRJJ"
          name="destination"
          value={payment.destination}
          type="text"
          disableUnderline={true}
          handleChange={handlePayment(index)}
          errText={destinationErrMsg[index]}
          maxLength={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Amount</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          name="amount"
          value={payment.amount}
          type="text"
          handleChange={handlePayment(index)}
          disableUnderline={true}
          maxLength={true}
        />
      </Grid>
    </Grid>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  payment: PropTypes.object,
  handlePayment: PropTypes.func,
  destinationErrMsg: PropTypes.array,
  index: PropTypes.number,
};

export default Index;
