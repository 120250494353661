import React from "react";
import loadingGif from "../../assets/images/loading.gif";
import Container from "@material-ui/core/Container";

const Index = ({
  description = null,
  action = null,
  onClick = null,
  ...props
}) => {
  const LabelComponent = () => {
    return description === "Loading..." ? (
      <img
        alt="loading gif"
        src={loadingGif}
        style={{ display: "block", margin: "0 auto", width: "90px" }}
      />
    ) : (
      <div className="margin-bottom-8 text-title-dark">{description}</div>
    );
  };

  const ButtonComponent = () => {
    return (
      <div
        onClick={onClick}
        className="text-para-white text-capitalized flat-button-blue"
      >
        {action}
      </div>
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        <div style={{ background: "#272831" }}>
          <div>
            {description && <LabelComponent />}
            {action && onClick && <ButtonComponent />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Index;
