import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigation";
import {
  Box,
  Typography,
  Container,
  makeStyles,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import TextFormField from "../../components/textfield/textfield";
import SelectOption from "../../components/select/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faCopy } from "@fortawesome/pro-light-svg-icons";
import Payment from "./payment";
import AllowTrust from "./allow-trust";
import ChangeTrust from "./change-trust";
import ManageData from "./manage-data";
import SignerConfig from "./signer-config";
import Snackbar from "./snackbar";
import Constants from "../../constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SelectNetworkComp from "../../components/keypair/selectNetworkComp";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { useForm } from "react-hook-form";
import axios from "axios";
import { animateScroll as scroll } from "react-scroll";
import EnterSecretKeyComp from "../../components/sign-transaction/enterSecretKeyComp";
import SubmitToNetworkResult from "../../containers/sign-transaction/SubmitToNetworkResult";
import SignTransactionResultComp from "../../components/sign-transaction/signTransactionResultComp";

const StellarSdk = require("stellar-sdk");
const StellarWallet = require("stellar-hd-wallet");

const useStyles = makeStyles((theme) => ({
  rootStepper: {
    width: "100%",
    "& .MuiPaper-root": {
      background: "none !important",
    },
    "& .MuiStepConnector-lineVertical": {
      marginLeft: "8px",
    },
    "& .MuiStepper-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiStepLabel-iconContainer": {
      color: "green",
    },
    "& .MuiStepContent-root": {
      marginLeft: "20px",
      marginTop: "-39px",
      paddingLeft: "33px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem !important",
    },
    "& .MuiStepLabel-label": {
      color: "cyan",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: Constants.fontColor.blue,
      borderRadius: "50%",
      border: "2px solid #342EAD",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "2px solid #6B6B6B",
    color: "#1e202c",
  },
  breadcrumbMain: {
    color: "#FFFFFF",
    display: "inline-block",
    fontFamily: "OpenSans-SemiBold",
    marginTop: "30px",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      margin: "16px",
    },
  },
  container: {
    backgroundColor: "#272831",
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
  },
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  errText: {
    color: "#e53935",
    fontSize: 16,
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  validateText: {
    color: "#767676",
    fontSize: "16px !important",
    margin: "8px 0px",
  },
  gridTextCenter: {
    display: "flex",
    alignItems: "center",
    height: "52px",
    marginTop: "16px",
  },
  transactionText: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  transactionBorder: {
    border: "1px solid #767676",
    borderRadius: "6px",
  },
  defaultPadding: {
    padding: "10px 15px",
  },
  gridItem: {
    padding: "10px 15px",
  },
  padding: {
    padding: 16,
  },
  border: {
    borderBottom: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "2px solid white",
    color: "#1e202c",
  },
  iconBtn: {
    backgroundColor: "#342EAD",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
  },
  btn: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    padding: "0px 50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  addOperationBtn: {
    fontSize: Constants.fontSize.small,
    borderRadius: 100,
    height: "auto",
    padding: "10px 20px",
  },
  removeIcon: {
    backgroundColor: "#1E1E26",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    padding: 0,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
  },
  successText: {
    color: "#75DAAD",
  },
  xdr: {
    wordBreak: "break-all",
    color: "white",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "16px 0px",
  },
}));

const options = [
  { value: "Payment", label: "Payment" },
  { value: "Account config", label: "Account config" },
  { value: "Signer config", label: "Signer config" },
  { value: "Change trust", label: "Change trust" },
  { value: "Allow trust", label: "Allow trust" },
];

function createData(placeholder, text, name, errMsg) {
  return { placeholder, text, name, errMsg };
}

const server = new StellarSdk.Server("https://api.testing.ssn.digital/");

const Index = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [sourceAccount, setSourceAccount] = useState("");
  const [sourceAccountErrMsg, setSourceAccountErrMsg] = useState("");
  const [destinationErrMsg, setDestinationErrMsg] = useState([]);
  const [trustorErrMsg, setTrustorErrMsg] = useState([]);
  const [xdr, setXdr] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isErrorBox, setIsErrorBox] = useState(false);
  const [operation, setOperation] = useState([
    { operationType: "Select operation type", object: null },
  ]);
  const [displaySignTransaction, setDisplaySignTransaction] = useState(false);
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    setError: setError2,
  } = useForm();

  useEffect(() => {
    const operations = [];
    operation.forEach((element) => {
      if (element.operationType.toLowerCase() === "payment") {
        if (element.object !== null) {
          if (handleValidationPayment()) {
            console.log("Asset", StellarSdk.Asset.native());
            const obj = StellarSdk.Operation.payment({
              destination: element.object.destination,
              asset: StellarSdk.Asset.native(),
              amount: element.object.amount,
            });
            operations.push(obj);
          }
        }
      } else if (element.operationType.toLowerCase() === "allow trust") {
        if (element.object !== null) {
          if (handleValidationPayment()) {
            const obj = StellarSdk.Operation.allowTrust({
              trustor: element.object.trustor,
              assetCode: element.object.assetCode,
              authorize: element.object.authorize,
            });
            operations.push(obj);
          }
        }
      } else if (element.operationType.toLowerCase() === "change trust") {
        if (element.object !== null) {
          if (handleValidationPayment()) {
            let obj = null;
            if (element.object.limit === "") {
              obj = StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(
                  element.object.asset.code,
                  element.object.asset.issuer
                ),
              });
            } else {
              obj = StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(
                  element.object.asset.code,
                  element.object.asset.issuer
                ),
                limit: element.object.limit,
              });
            }
            operations.push(obj);
          }
        }
      } else if (element.operationType.toLowerCase() === "account config") {
        if (element.object !== null) {
          if (handleValidationPayment()) {
            element.object.forEach((item) => {
              const obj = StellarSdk.Operation.manageData({
                name: item.name,
                value: "",
              });
              operations.push(obj);
            });
          }
        }
      } else if (element.operationType.toLowerCase() === "signer config") {
        if (element.object !== null) {
          if (handleValidationPayment()) {
            element.object.forEach((item) => {
              let obj = null;
              if (item.weight.toLowerCase() !== "weight number") {
                obj = StellarSdk.Operation.setOptions({
                  inflationDest: item.inflationDest,
                  masterWeight: item.weight,
                });
              } else {
                obj = StellarSdk.Operation.setOptions({
                  inflationDest: item.inflationDest,
                });
              }
              operations.push(obj);
            });
          }
        }
      }
    });

    if (handleValidationPayment()) {
      console.log("Validate Successfully", operation);
      setActiveStep(3);
      paymentOperation(operations);
    } else {
      setDisplaySignTransaction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, sourceAccount]);

  const handleValidationPayment = () => {
    let formIsValid = true;

    if (sourceAccount === "") {
      formIsValid = false;
    }

    for (let i = 0; i < operation.length; i++) {
      if (operation[i].operationType.toLocaleLowerCase() === "payment") {
        if (operation[i].object !== null) {
          if (operation[i].object.destination === "") {
            formIsValid = false;
            break;
          } else if (!validatePublicKey(operation[i].object.destination)) {
            formIsValid = false;
            break;
          } else {
            formIsValid = true;
          }
          if (operation[i].object.amount === "") {
            formIsValid = false;
            break;
          } else {
            if (
              isNaN(operation[i].object.amount) ||
              parseInt(operation[i].object.amount) <= 0 ||
              countDecimals(operation[i].object.amount) > 7
            ) {
              formIsValid = false;
              break;
            } else {
              formIsValid = true;
            }
          }
        } else {
          formIsValid = false;
          break;
        }
      } else if (
        operation[i].operationType.toLocaleLowerCase() === "allow trust"
      ) {
        if (operation[i].object !== null) {
          if (operation[i].object.trustor === "") {
            formIsValid = false;
            break;
          } else if (!validatePublicKey(operation[i].object.trustor)) {
            formIsValid = false;
            break;
          } else {
            formIsValid = true;
          }
          if (operation[i].object.assetCode === "") {
            formIsValid = false;
            break;
          } else {
            if (operation[i].object.assetCode.length > 12) {
              formIsValid = false;
              break;
            } else {
              formIsValid = true;
            }
          }
        } else {
          formIsValid = false;
          break;
        }
      } else if (
        operation[i].operationType.toLocaleLowerCase() === "change trust"
      ) {
        if (operation[i].object.asset.code === "") {
          formIsValid = false;
          break;
        } else if (operation[i].object.asset.issuer === "") {
          formIsValid = false;
          break;
        } else if (!validatePublicKey(operation[i].object.asset.issuer)) {
          formIsValid = false;
          break;
        } else if (
          isNaN(operation[i].object.limit) ||
          parseInt(operation[i].object.limit) <= 0 ||
          countDecimals(operation[i].object.limit) > 7
        ) {
          formIsValid = false;
          break;
        }
      } else if (
        operation[i].operationType.toLocaleLowerCase() === "account config"
      ) {
        if (handleValidationManageData(i) === false) {
          formIsValid = false;
          break;
        }
      } else if (
        operation[i].operationType.toLocaleLowerCase() === "signer config"
      ) {
        if (handleValidateSigner(i) === false) {
          formIsValid = false;
          break;
        }
      } else {
        formIsValid = false;
        break;
      }
    }

    return formIsValid;
  };

  const paymentOperation = (operations) => {
    server
      .accounts()
      .accountId(sourceAccount)
      .call()
      .then((res) => {
        const account = new StellarSdk.Account(sourceAccount, res.sequence);
        const transaction = new StellarSdk.TransactionBuilder(account, {
          fee: "10000000",
          networkPassphrase: "ssn_testing_network",
        });
        transaction.operations = operations;
        transaction.setTimeout(0);
        const tx = transaction.build();
        console.log(tx.toEnvelope().toXDR("base64"));

        setXdr(tx.toEnvelope().toXDR("base64"));

        account.incrementSequenceNumber();

        return tx;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validatePublicKey = (key) => {
    return StellarSdk.StrKey.isValidEd25519PublicKey(key);
  };

  const handleAllowTrustAuthorizeChange = (index) => (event, value) => {
    setIsErrorBox(true);
    if (value != null) {
      const newArr = [...operation];
      newArr[index].object = {
        ...newArr[index].object,
        authorize: value,
      };
      setOperation(newArr);
    }
  };

  const handleAllowTrustTextChange = (index) => (event) => {
    setIsErrorBox(true);
    event.preventDefault();
    if (event.target.value) {
      if (event.target.name === "trustor") {
        if (!validatePublicKey(event.target.value)) {
          const newArrMsg = [...trustorErrMsg];
          newArrMsg[index] = "Public key is invalid";
          setTrustorErrMsg(newArrMsg);
        } else {
          const newArrMsg = [...trustorErrMsg];
          newArrMsg[index] = "";
          setTrustorErrMsg(newArrMsg);
        }
      }
    } else {
      const newArrMsg = [...trustorErrMsg];
      newArrMsg[index] = "";
      setTrustorErrMsg(newArrMsg);
    }

    const newArr = [...operation];
    newArr[index].object = {
      ...newArr[index].object,
      [event.target.name]: event.target.value,
    };
    setOperation(newArr);
  };

  const handleChangeAssetIssuerTextChange = (index) => (event) => {
    event.preventDefault();
    setIsErrorBox(true);
    const newArr = [...operation];
    newArr[index].object.asset.issuer = event.target.value;
    setOperation(newArr);
  };

  const handleChangeTrustTextChange = (index) => (event) => {
    event.preventDefault();
    setIsErrorBox(true);
    const newArr = [...operation];
    newArr[index].object = {
      ...newArr[index].object,
      [event.target.name]: event.target.value,
    };
    setOperation(newArr);
  };

  const handleManageDataTextChange = (index, objIndex) => (event) => {
    event.preventDefault();
    setIsErrorBox(true);
    const newArr = [...operation];
    newArr[index].object[objIndex] = {
      ...newArr[index].object[objIndex],
      name: event.target.value,
      errMsg: "",
    };
    setOperation(newArr);
  };

  const handleChangeWeightText = (index, objIndex) => (event) => {
    event.preventDefault();
    setIsErrorBox(true);
    const newArr = [...operation];
    if (event.target.value) {
      if (validatePublicKey(event.target.value)) {
        newArr[index].object[objIndex] = {
          ...newArr[index].object[objIndex],
          errMsg: "",
        };
      } else {
        newArr[index].object[objIndex] = {
          ...newArr[index].object[objIndex],
          errMsg: "Public key is invalid",
        };
      }
    } else {
      newArr[index].object[objIndex] = {
        ...newArr[index].object[objIndex],
        errMsg: "",
      };
    }

    newArr[index].object[objIndex] = {
      ...newArr[index].object[objIndex],
      [event.target.name]: event.target.value,
    };

    setOperation(newArr);
  };

  const handleChangeWeight = (option, index, objIndex) => {
    const newArr = [...operation];
    newArr[index].object[objIndex].weight = option.label;

    setOperation(newArr);
  };

  const handleAssetChange = (index) => (event) => {
    event.preventDefault();
    const newArr = [...operation];
    newArr[index].object.asset.code = event.target.value;
    setOperation(newArr);
  };

  const handleAddSigner = (index) => {
    const operations = [...operation];
    const newSigner = {
      inflationDest: "",
      weight: "Weight Number",
    };

    const signers = operations[index].object;
    signers.push(newSigner);
    setOperation(operations);
  };

  const handleRemoveSigner = (index, objIndex) => {
    const operations = [...operation];
    operations[index].object.splice(objIndex, 1);
    setOperation(operations);
  };

  const handleManageData = (index, value) => {
    setIsErrorBox(true);
    const newArr = [...operation];
    if (value.name !== "") {
      newArr[index].object = value;
      newArr[index].object.forEach((item, itemIndex) => {
        if (item.name === "") {
          newArr[index].object[itemIndex].errMsg =
            item.text + "is a required field";
        }
      });
      setOperation(newArr);
    }
  };

  const handlePayment = (index) => (event) => {
    setIsErrorBox(true);
    event.preventDefault();
    if (event.target.value) {
      if (event.target.name === "destination") {
        if (!validatePublicKey(event.target.value)) {
          const newArrMsg = [...destinationErrMsg];
          newArrMsg[index] = "Public key is invalid";
          setDestinationErrMsg(newArrMsg);
        } else {
          const newArrMsg = [...destinationErrMsg];
          newArrMsg[index] = "";
          setDestinationErrMsg(newArrMsg);
        }
      }
    } else {
      const newArrMsg = [...destinationErrMsg];
      newArrMsg[index] = "";
      setDestinationErrMsg(newArrMsg);
    }

    const newArr = [...operation];
    newArr[index].object = {
      ...newArr[index].object,
      [event.target.name]: event.target.value,
    };
    setOperation(newArr);
  };

  const handleChange = (event) => {
    setIsErrorBox(true);
    if (event.target.value) {
      if (!validatePublicKey(event.target.value)) {
        setSourceAccountErrMsg("Public key is invalid");
      } else {
        setSourceAccountErrMsg("");
      }
    } else {
      setSourceAccountErrMsg("");
    }
    setSourceAccount(event.target.value);
  };

  const handleChangeOperationType = (option, index) => {
    setIsErrorBox(true);
    const operations = [...operation];
    operations[index].operationType = option.value;
    if (option.value.toLocaleLowerCase() === "payment") {
      operations[index].object = {
        destination: "",
        asset: "Native",
        amount: "",
      };
    } else if (option.value.toLocaleLowerCase() === "allow trust") {
      operations[index].object = {
        trustor: "",
        assetCode: "",
        authorize: true,
      };
    } else if (option.value.toLocaleLowerCase() === "change trust") {
      operations[index].object = {
        asset: { code: "KHR", issuer: "" },
        limit: "",
      };
    } else if (option.value.toLocaleLowerCase() === "account config") {
      const obj = [
        createData(
          "Sabay Digital Corporation Ltd.",
          "Registration Name",
          "",
          ""
        ),
        createData("Jon", "Contact Name", "", ""),
        createData("Cambodia", "Registration Country", "", ""),
        createData("012 345 678", "Contact Number", "", ""),
        createData("Ministry", "Registration Authority", "", ""),
        createData("sabay@gmail.com", "Contact Email", "", ""),
        createData("0123456789", "Registration Number", "", ""),
        createData("sabay@gmail.com", "Trust Approval Email", "", ""),
        createData("Digital", "Service Name", "", ""),
      ];
      operations[index].object = obj;
    } else if (option.value.toLocaleLowerCase() === "signer config") {
      const obj = [
        {
          inflationDest: "",
          weight: "Weight Number",
          errMsg: "",
        },
      ];
      operations[index].object = obj;
    }
    setOperation(operations);
  };

  const handleClickAddOperation = () => {
    const operations = [...operation];
    const newOperation = {
      operationType: "Select operation type",
      object: null,
    };
    operations.push(newOperation);
    setOperation(operations);
  };

  const handleRemoveOperation = (index) => {
    const operations = [...operation];
    operations.splice(index, 1);
    setOperation(operations);
  };

  const countDecimals = (value) => {
    if (value % 1 !== 0) return value.toString().split(".")[1].length;
    return 0;
  };

  const handleViewXdr = () => {
    props.history.push({
      pathname: "/xdr-viewer",
      XDR: xdr,
    });
  };

  const handleTransactionSigner = () => {
    setActiveStep(4);
    setDisplaySignTransaction(true);
  };

  const handleCopied = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackbar(false);
  };

  const handleValidationManageData = (index) => {
    let formIsValid = true;
    const newArr = [...operation];

    if (newArr[index].object !== null) {
      newArr[index].object.forEach((item) => {
        if (item.name === "") {
          formIsValid = false;
        }
      });
    } else {
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleValidateSigner = (index) => {
    let formIsValid = true;
    const newArr = [...operation];

    if (newArr[index].object !== null) {
      newArr[index].object.forEach((item) => {
        if (item.inflationDest !== "") {
          if (!validatePublicKey(item.inflationDest)) {
            formIsValid = false;
          }
        } else {
          formIsValid = false;
        }
        if (item.weight.toLowerCase() === "weight number") {
          formIsValid = false;
        }
      });
    } else {
      formIsValid = false;
    }

    return formIsValid;
  };

  const [selectedNetwork, setselectedNetwork] = useState("Select Network");
  const handleChangeNetwork = (e) => {
    setselectedNetwork(e.value);
  };

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (selectedNetwork === "Testing" || selectedNetwork === "Production") {
      setActiveStep(2);
    }
  }, [selectedNetwork]);

  const [xdrForViewer, setXdrForViewer] = useState();
  const [loading, setLoading] = useState(false);
  const [typeOfSecret, setTypeOfSecret] = useState("Secret Keys");
  const [submitToNetworkResult, setSubmitToNetworkResult] = useState();

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const handleSecretChange = (event, secret) => {
    if (secret !== null) {
      setTypeOfSecret(secret);
    }
  };

  // Sign the transaction
  const signTransaction = (
    XdrBase64TxString,
    signerKeypair,
    networkPassphrase
  ) => {
    const transaction = StellarSdk.TransactionBuilder.fromXDR(
      XdrBase64TxString,
      networkPassphrase
    );
    transaction.sign(StellarSdk.Keypair.fromSecret(signerKeypair));
    return transaction.toXDR();
  };

  // Get Secret key from secret word
  const getKeypairCloneFromSdk = (seed, pathId) => {
    try {
      const wallet = StellarWallet.fromMnemonic(seed);
      return wallet.getKeypair(pathId);
    } catch (err) {
      console.log("get keypair err:", err.message);
      return err.message;
    }
  };

  const onSubmitSignTransaction = (formData) => {
    if (typeOfSecret === "Secret Keys") {
      try {
        Promise.resolve(
          signTransaction(
            xdr,
            formData.signerSecret,
            Constants.getNetworkPassphrase(selectedNetwork)
          )
        ).then((data) => {
          setXdrForViewer(data);
          scrollToBottom();
        });
      } catch (error) {
        console.log("error:");
        setError2("signerSecret", {
          type: "incorrect",
        });
      }
    }

    if (typeOfSecret === "Secret Words") {
      try {
        const keypair = getKeypairCloneFromSdk(formData.signerSecret, 0);
        Promise.resolve(
          signTransaction(
            xdr,
            keypair.secret(),
            Constants.getNetworkPassphrase(selectedNetwork)
          )
        ).then((data) => {
          setXdrForViewer(data);
          scrollToBottom();
        });
      } catch (error) {
        console.log("error:");
        setError2("signerSecret", {
          type: "incorrect",
        });
      }
    }
  };

  const submitToNetwork = () => {
    setLoading(true);
    axios
      .post(`${Constants.getSsnApi(selectedNetwork)}/transactions`, {
        tx: xdrForViewer,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setSubmitToNetworkResult(res.data);
        scrollToBottom();
        setActiveStep(6);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        setSubmitToNetworkResult(err?.response?.data?.extras?.result_codes);
        scrollToBottom();
        setActiveStep(6);
      });
  };

  return (
    <div>
      <Navbar selectedTitle="tools" />
      <Container maxWidth="xl">
        <div className={classes.content}>
          <Typography
            className={classes.breadcrumbMain}
            style={{
              fontSize: isXs ? "32px" : "32px",
            }}
          >
            Transaction Builder
          </Typography>

          <div className={classes.rootStepper}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              nonLinear={true}
            >
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <SelectNetworkComp
                    selectedNetwork={selectedNetwork}
                    handleChangeNetwork={handleChangeNetwork}
                    label={"Select a Network"}
                    displayButton={false}
                  />
                </StepContent>
              </Step>

              {selectedNetwork !== "Select Network" && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <Box className={classes.container}>
                      <div className={classes.border}>
                        <Typography
                          className={classes.title}
                          style={{ padding: "20px 30px" }}
                        >
                          Enter the public key of the source account that you
                          wish you build transaction for.
                        </Typography>
                      </div>
                      <div>
                        <Grid container className={classes.defaultPadding}>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            className={classes.gridTextCenter}
                            style={{ padding: "10px 15px" }}
                          >
                            <Typography className={classes.text}>
                              Source Account
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={10}
                            lg={10}
                            style={{ padding: 16 }}
                          >
                            <TextFormField
                              placeholder="Example: GATYCTIG2VZI2537PLD43QOO3ZBWIOLDT5JPWYPFQRJJFTMPUQKQ7NJED43QOO3ZBWIOLDT5JPWYPFQRJJ"
                              name="sourceAccount"
                              value={sourceAccount}
                              type="text"
                              disableUnderline={true}
                              handleChange={handleChange}
                              errText={sourceAccountErrMsg}
                              maxLength={false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} md={2} lg={2} />
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={10}
                            lg={10}
                            className={classes.gridItem}
                            style={{ paddingLeft: 16 }}
                          >
                            <Typography
                              className={`${classes.text} ${classes.marginTop}`}
                            >
                              If you don’t have an account yet, you can create
                              and fund a test net account with the &nbsp;
                              <a
                                className={classes.title}
                                href="/create-ssn-account"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                account creator.
                              </a>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              )}

              {selectedNetwork !== "Select Network" && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    {operation?.map((item, index) => (
                      <Box
                        key={index}
                        className={classes.container}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: index === 0 ? "0" : "30px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Grid
                            container
                            className={classes.defaultPadding}
                            spacing={1}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              className={classes.row}
                              style={{ padding: 16 }}
                            >
                              <div
                                className={`${classes.center} ${classes.number}`}
                              >
                                <Typography className={classes.title}>
                                  {index + 1}
                                </Typography>
                              </div>
                              <Typography
                                className={classes.title}
                                style={{ marginLeft: "16px" }}
                              >
                                Operation Type
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ padding: 16 }}>
                              <SelectOption
                                options={options}
                                index={index}
                                selectedOption={{
                                  label: item.operationType,
                                  value: item.operationType,
                                }}
                                borderRadius="5px"
                                borderColor="white"
                                handleChangeValue={handleChangeOperationType}
                              />
                            </Grid>
                            {operation.length > 1 && (
                              <div
                                style={{
                                  padding: "8px 8px",
                                  flexGrow: 1,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleRemoveOperation(index)}
                                  className={`${classes.center} ${classes.removeIcon}`}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    color="white"
                                    style={{ fontSize: 12, color: "#767676" }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </Grid>
                          {item.operationType?.toLocaleLowerCase() ===
                          "payment" ? (
                            <Payment
                              payment={item.object}
                              index={index}
                              handlePayment={handlePayment}
                              destinationErrMsg={destinationErrMsg}
                            />
                          ) : item.operationType?.toLocaleLowerCase() ===
                            "allow trust" ? (
                            <AllowTrust
                              allowTrust={item.object}
                              index={index}
                              handleAllowTrustTextChange={
                                handleAllowTrustTextChange
                              }
                              handleAllowTrustAuthorizeChange={
                                handleAllowTrustAuthorizeChange
                              }
                              trustorErrMsg={trustorErrMsg}
                            />
                          ) : item.operationType?.toLocaleLowerCase() ===
                            "change trust" ? (
                            <ChangeTrust
                              changeTrust={item.object}
                              index={index}
                              handleChangeAssetIssuerTextChange={
                                handleChangeAssetIssuerTextChange
                              }
                              handleChangeTrustTextChange={
                                handleChangeTrustTextChange
                              }
                              handleAssetChange={handleAssetChange}
                            />
                          ) : item.operationType?.toLocaleLowerCase() ===
                            "account config" ? (
                            <ManageData
                              manageData={item.object}
                              index={index}
                              handleManageDataTextChange={
                                handleManageDataTextChange
                              }
                              handleManageData={handleManageData}
                            />
                          ) : (
                            item.operationType?.toLocaleLowerCase() ===
                              "signer config" && (
                              <SignerConfig
                                singerConfig={item.object}
                                handleChangeWeightText={handleChangeWeightText}
                                handleChangeWeight={handleChangeWeight}
                                handleAddSigner={handleAddSigner}
                                handleRemoveSigner={handleRemoveSigner}
                                index={index}
                              />
                            )
                          )}
                        </div>
                      </Box>
                    ))}
                    {selectedNetwork !== "Select Network" && (
                      <div
                        className={classes.center}
                        style={{ marginTop: "16px" }}
                      >
                        <Button
                          className={`${classes.btn} ${classes.addOperationBtn}`}
                          onClick={handleClickAddOperation}
                          startIcon={
                            <FontAwesomeIcon
                              icon={faPlus}
                              color="white"
                              style={{ fontSize: 18, marginRight: 8 }}
                            />
                          }
                        >
                          Add Operation
                        </Button>
                      </div>
                    )}
                  </StepContent>
                </Step>
              )}
              {isErrorBox && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <Box
                      className={classes.container}
                      style={{ padding: "20px 30px" }}
                    >
                      {sourceAccount === "" ? (
                        <Typography className={classes.errText}>
                          Form validation errors:
                        </Typography>
                      ) : !handleValidationPayment() ? (
                        <Typography className={classes.errText}>
                          Transaction building errors:
                        </Typography>
                      ) : (
                        <Typography className={classes.successText}>
                          Success! Transaction Envelope XDR:
                        </Typography>
                      )}
                      {sourceAccount === "" ? (
                        <div>
                          <Typography className={classes.validateText}>
                            - Source Account is a required field
                          </Typography>
                        </div>
                      ) : !validatePublicKey(sourceAccount) ? (
                        <Typography className={classes.validateText}>
                          - Source Account is invalid
                        </Typography>
                      ) : (
                        <div>
                          {operation?.map((item, index) => (
                            <div key={index}>
                              {item.operationType ===
                              "Select operation type" ? (
                                <Typography className={classes.validateText}>
                                  - Operation #{index + 1}: Operation type is
                                  required
                                </Typography>
                              ) : item.operationType.toLocaleLowerCase() ===
                                "payment" ? (
                                <div>
                                  {item.object.destination === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Payment
                                      operation requires destination
                                    </Typography>
                                  ) : !validatePublicKey(
                                      item.object.destination
                                    ) ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: destination is
                                      invalid
                                    </Typography>
                                  ) : item.object.asset === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Payment
                                      operation requires asset
                                    </Typography>
                                  ) : item.object.amount === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Payment
                                      operation requires amount
                                    </Typography>
                                  ) : (
                                    (isNaN(item.object.amount) ||
                                      parseInt(item.object.amount) <= 0 ||
                                      countDecimals(item.object.amount) >
                                        7) && (
                                      <Typography
                                        className={classes.validateText}
                                      >
                                        - Operation #{index + 1}: amount is
                                        invalid
                                      </Typography>
                                    )
                                  )}
                                </div>
                              ) : item.operationType.toLocaleLowerCase() ===
                                "allow trust" ? (
                                <div>
                                  {item.object.trustor === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: ALlow Trust
                                      operation requires trustor
                                    </Typography>
                                  ) : !validatePublicKey(
                                      item.object.trustor
                                    ) ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: trustor is
                                      invalid
                                    </Typography>
                                  ) : item.object.assetCode === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: ALlow Trust
                                      operation requires asset code
                                    </Typography>
                                  ) : item.object.assetCode.length > 12 ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Asset code must
                                      be 12 characters at max.
                                    </Typography>
                                  ) : (
                                    item.object.authorize === "" && (
                                      <Typography
                                        className={classes.validateText}
                                      >
                                        - Operation #{index + 1}: ALlow Trust
                                        operation requires authorization setting
                                      </Typography>
                                    )
                                  )}
                                </div>
                              ) : item.operationType.toLocaleLowerCase() ===
                                "change trust" ? (
                                <div>
                                  {item.object.asset.issuer === "" ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Change Trust
                                      operation requires Issuer Account ID
                                    </Typography>
                                  ) : !validatePublicKey(
                                      item.object.asset.issuer
                                    ) ? (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Issuer Account
                                      ID is invalid
                                    </Typography>
                                  ) : (
                                    item.object.limit !== "" &&
                                    (isNaN(item.object.limit) ||
                                      parseInt(item.object.limit) <= 0 ||
                                      countDecimals(item.object.limit) > 7) && (
                                      <Typography
                                        className={classes.validateText}
                                      >
                                        - Operation #{index + 1}: limit Account
                                        is invalid
                                      </Typography>
                                    )
                                  )}
                                </div>
                              ) : item.operationType.toLocaleLowerCase() ===
                                "account config" ? (
                                <div>
                                  {!handleValidationManageData(index) && (
                                    <Typography
                                      className={classes.validateText}
                                    >
                                      - Operation #{index + 1}: Account config
                                      operation is invalid
                                    </Typography>
                                  )}
                                </div>
                              ) : item.operationType.toLocaleLowerCase() ===
                                "signer config" ? (
                                <div>
                                  {item.object.map((signer, signerIndex) =>
                                    signer.inflationDest === "" ? (
                                      <Typography
                                        className={classes.validateText}
                                        key={signerIndex}
                                      >
                                        Operation #{index + 1}: Signer Config
                                        operation requires public key
                                      </Typography>
                                    ) : !validatePublicKey(
                                        signer.inflationDest
                                      ) ? (
                                      <Typography
                                        className={classes.validateText}
                                        key={signerIndex}
                                      >
                                        - Operation #{index + 1}: Signer Config
                                        public key is invalid
                                      </Typography>
                                    ) : (
                                      signer.weight.toLowerCase() ===
                                        "weight number" && (
                                        <Typography
                                          className={classes.validateText}
                                          key={signerIndex}
                                        >
                                          Operation #{index + 1}: Signer Config
                                          operation requires Weight
                                        </Typography>
                                      )
                                    )
                                  )}
                                </div>
                              ) : (
                                <Typography className={classes.validateText}>
                                  Other operation is coming soon
                                </Typography>
                              )}
                            </div>
                          ))}

                          {handleValidationPayment() && (
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                className={classes.transactionText}
                              >
                                <Typography className={classes.text}>
                                  XDR
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={10} style={{ padding: 8 }}>
                                <div
                                  className={`${classes.transactionText} ${classes.transactionBorder}`}
                                >
                                  <Typography
                                    className={classes.xdr}
                                    style={{
                                      padding: "10px 0px",
                                      margin: "0px 10px",
                                    }}
                                  >
                                    {xdr}
                                  </Typography>

                                  <div
                                    style={{
                                      justifyContent: "flex-end",
                                      display: "flex",
                                      flexGrow: 1,
                                    }}
                                  >
                                    <CopyToClipboard
                                      text={xdr}
                                      onCopy={() => handleCopied()}
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          style={{ color: "#767676" }}
                                        />
                                      </IconButton>
                                    </CopyToClipboard>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12} className={classes.alignRight}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className={classes.btn}
                                  onClick={handleTransactionSigner}
                                >
                                  Sign in Transaction Signer
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className={classes.btn}
                                  style={{ marginLeft: "16px" }}
                                  onClick={handleViewXdr}
                                >
                                  View in XDR Viewer
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              )}

              {displaySignTransaction && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <EnterSecretKeyComp
                      handleSubmit={handleSubmit2}
                      onSubmit={onSubmitSignTransaction}
                      register={register2}
                      errors={errors2}
                      typeOfSecret={typeOfSecret}
                      setTypeOfSecret={setTypeOfSecret}
                      handleSecretChange={handleSecretChange}
                      setActiveStep={setActiveStep}
                      transactionBuilderPage={true}
                    />
                  </StepContent>
                </Step>
              )}

              {xdrForViewer && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <SignTransactionResultComp
                      scrollToBottom={scrollToBottom}
                      xdrForViewer={xdrForViewer}
                      submitToNetwork={submitToNetwork}
                      loading={loading}
                      setActiveStep={setActiveStep}
                    />
                  </StepContent>
                </Step>
              )}

              {submitToNetworkResult && (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <SubmitToNetworkResult
                      submitToNetworkResult={submitToNetworkResult}
                      scrollToBottom={scrollToBottom}
                    />
                  </StepContent>
                </Step>
              )}
            </Stepper>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          text="XDR copied to clipboard"
          handleCloseSnackBar={handleCloseSnackBar}
        />
      </Container>
    </div>
  );
};

export default Index;
