import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigation";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Checkbox from "@material-ui/core/Checkbox";
import loadingGif from "../../assets/images/loading.gif";

import GenerateKeypairsComp from "../../components/keypair/selectNetworkComp";
import SecretWordsComp from "../../components/keypair/secretWordsComp";
import KeypairForCreateAccComp from "../../components/create-ssn-account/keypairForCreateAccComp";
import MultipleChoiceQuestionsComp from "../../components/keypair/multipleChoiceQuestionsComp";
import EnterSecretWordsComp from "../../components/keypair/enterSecretWordsComp";
import RegistrationFormComp from "../../components/create-ssn-account/registrationFormComp";
import AccountCreatedSuccessfulComp from "../../components/create-ssn-account/accountCreatedSuccessfulComp";
import DialogSuccessful from "../../components/utilities/dialogSuccessful";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Constants from "../../constants";

const DashboardSdk = require("sdk.dashboard.ssn.digital");
const StellarSdk = require("stellar-sdk");
const StellarWallet = require("stellar-hd-wallet");
const server = new StellarSdk.Server("https://api.testing.ssn.digital/");

const useStyles = makeStyles((theme) => ({
  breadcrumbMain: {
    color: Constants.fontColor.white,
    display: "inline-block",
    fontFamily: Constants.font.semiBoldFont,
  },

  root: {
    width: "100%",
    "& .MuiPaper-root": {
      background: "none !important",
    },
    "& .MuiStepConnector-lineVertical": {
      marginLeft: "8px",
    },
    "& .MuiStepper-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiStepLabel-iconContainer": {
      color: "green",
    },
    "& .MuiStepContent-root": {
      marginLeft: "20px",
      marginTop: "-39px",
      paddingLeft: "33px",
      // borderLeft: "1px solid #bdbdbd",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem !important",
    },
    "& .MuiStepLabel-label": {
      color: "cyan",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: Constants.fontColor.blue,
      borderRadius: "50%",
      border: "2px solid #342EAD",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  icon: {
    // backgroundColor: '#1e1f2c',
    borderRadius: "50%",
    border: "2px solid #6B6B6B",
    color: "#1e202c",
  },
}));

const Index = () => {
  const classes = useStyles();

  const [selectedNetwork, setselectedNetwork] = useState("Testing");
  const handleChangeNetwork = (e) => {
    setselectedNetwork(e.value);
  };

  const [secretWords, setSecretWords] = useState();
  const [publicKeys, setPublicKeys] = useState([]);
  const [secretKeys, setSecretKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateSecretWords = () => {
    setSecretWords(DashboardSdk.getSeed());
  };

  const getKeypairCloneFromSdk = (seed, pathId) => {
    try {
      const wallet = StellarWallet.fromMnemonic(seed);
      return wallet.getKeypair(pathId);
    } catch (err) {
      console.log("get keypair err:", err.message);
      return err.message;
    }
  };

  useEffect(() => {
    if (secretWords) {
      let keypair = null;
      let i;
      let publicKeyTmp = [];
      let secretKeyTmp = [];
      const amountOfKeysUserCanGenerate = 2;
      for (i = 0; i < amountOfKeysUserCanGenerate; i++) {
        keypair = getKeypairCloneFromSdk(secretWords, i);
        publicKeyTmp = [keypair.publicKey(), ...publicKeyTmp];
        secretKeyTmp = [keypair.secret(), ...secretKeyTmp];
        setPublicKeys(publicKeyTmp);
        setSecretKeys(secretKeyTmp);
        if (i + 1 === amountOfKeysUserCanGenerate) {
          setLoading(false);
        }
      }
    }
  }, [secretWords]);

  useEffect(() => {
    if (secretWords) {
      if (selectedNetwork === "Testing") {
        setActiveStep(2);
      } else if (selectedNetwork === "Production") {
        setActiveStep(2);
      }
    }
  }, [secretWords, selectedNetwork]);

  useEffect(() => {
    // setChecked({
    //   "0": false,
    //   "1": false,
    // })
    setDisplaySuccessfulComp(false);
    setSecretWords(null);
  }, [selectedNetwork]);

  // // Generate Path ID
  // let i;
  // let path = [0];
  // for (i = 1; i < publicKeys.length; i++) {
  //   path = [...path, i]
  // }
  // const path = ["Master", "API"];
  const path = ["Master", "API"];

  const [checked, setChecked] = useState({
    0: false,
    1: false,
  });

  const handleChangeCheckBox = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  // // Generate Checkbuttons
  let checkBox = [];
  let j;
  for (j = 0; j < publicKeys.length; j++) {
    checkBox = [
      ...checkBox,
      <Checkbox
        key={j}
        className={classes.checkBox}
        checked={checked[j]}
        color="primary"
        onChange={handleChangeCheckBox}
        inputProps={{ "aria-label": "primary checkbox", name: j }}
        style={{ padding: 0, color: Constants.fontColor.white }}
      />,
    ];
  }

  const publicAndSecretKeyCombined = [path, publicKeys, secretKeys, checkBox];

  const checkedInArrayFormat = Object.entries(checked);
  let userHaveCheckedAll = true;
  const userCheckedAll = () => {
    checkedInArrayFormat.map((data) => {
      if (data[1] === false) {
        userHaveCheckedAll = false;
      }
      return null;
    });
  };
  userCheckedAll();

  const [activeStep, setActiveStep] = useState(0);
  const handleNext = (amount) => {
    setActiveStep(activeStep + amount);
  };

  // Multiple choice questions start
  const [questions, setQuestions] = useState([]);
  const [multipleAnswers, setMultipleAnswers] = useState([]);
  const secretWordsToArray = secretWords?.split(" ");

  const randomizeChoice = () =>{
    // Generate 3 Questions in Number Index
    const generateQuestion = () => {
      return Math.floor(Math.random() * secretWordsToArray.length);
    };
    const allQuestions = [
      generateQuestion(),
      generateQuestion(),
      generateQuestion(),
    ];
    setQuestions(allQuestions);

    // Generate 1 Correct Answer and 2 Wrong + No Duplicate
    const generateMultipleAnswer = (correctAnswerIndex) => {
      const multipleAnswersTemp = [correctAnswerIndex];
      let i = 0;
      while (i < 2) {
        const tempAnswerChoiceIncorrect = generateQuestion();
        if (
          tempAnswerChoiceIncorrect !== multipleAnswersTemp[0] &&
          tempAnswerChoiceIncorrect !== multipleAnswersTemp[1]
        ) {
          multipleAnswersTemp.push(tempAnswerChoiceIncorrect);
          i++;
        }
      }
      return multipleAnswersTemp;
    };

    // Shuffle the multiple choices
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    };

    // Set the multiple choices
    setMultipleAnswers([
      shuffleArray(generateMultipleAnswer(allQuestions[0])),
      shuffleArray(generateMultipleAnswer(allQuestions[1])),
      shuffleArray(generateMultipleAnswer(allQuestions[2])),
    ]);
  }    

  /*eslint-disable */
  useEffect(() => {
    if (secretWords) {
      randomizeChoice();
    }
  }, [secretWords]);
  /* eslint-enable */

  // Multiple choice questions end

  const [userChosenAnswer1, setUserChosenAnswer1] = useState("");
  const handleUserChosenAnswerChange1 = (event, answer) => {
    if (answer !== null) {
      setUserChosenAnswer1(answer);
    }
  };

  const [userChosenAnswer2, setUserChosenAnswer2] = useState("");
  const handleUserChosenAnswerChange2 = (event, answer) => {
    if (answer !== null) {
      setUserChosenAnswer2(answer);
    }
  };

  const [userChosenAnswer3, setUserChosenAnswer3] = useState("");
  const handleUserChosenAnswerChange3 = (event, answer) => {
    if (answer !== null) {
      setUserChosenAnswer3(answer);
    }
  };

  const checkIfUserChooseCorrectly = () => {
    if (
      userChosenAnswer1 === questions[0] &&
      userChosenAnswer2 === questions[1] &&
      userChosenAnswer3 === questions[2]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [displayPage1, setDisplayPage1] = useState(true);
  const [displayPage2, setDisplayPage2] = useState(false);

  const [userEnterSecretWords, setUserEnterSecretWords] = useState();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (formData) => {
    setUserEnterSecretWords(formData);
    setActiveStep(1);
  };

  // console.log(secretWordsToArray);
  // console.log(userEnterSecretWords);

  let correctSecretWord = false;
  if (userEnterSecretWords) {
    const userEnterSecretWordsToArray = Object.entries(userEnterSecretWords);
    for (let i = 0; i < 12; i++) {
      if (secretWordsToArray[i] === userEnterSecretWordsToArray[i][1]) {
        correctSecretWord = true;
      } else {
        correctSecretWord = false;
      }
    }
    console.log(userEnterSecretWordsToArray);
  }

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const {
    register: registerForm,
    handleSubmit: handleSubmitRegistrationForm,
    errors: errorsRegistrationForm,
  } = useForm();

  console.log(errorsRegistrationForm);
  const onSubmitRegisterForm = (formData) => {
    console.log(formData);
    if (selectedNetwork === "Testing") {
      server
        .accounts()
        .accountId(Constants.sourceAccount.publicKey)
        .call()
        .then((res) => {
          const account = new StellarSdk.Account(
            Constants.sourceAccount.publicKey,
            res.sequence
          );
          const transaction = new StellarSdk.TransactionBuilder(account, {
            fee: "100000000",
            networkPassphrase: "ssn_testing_network",
          })
            .addOperation(
              StellarSdk.Operation.createAccount({
                destination: publicAndSecretKeyCombined[1][1],
                startingBalance: "200",
              })
            )
            .addOperation(
              StellarSdk.Operation.manageData({
                name: "Company Name",
                value: formData.company_name,
              })
            )
            .addOperation(
              StellarSdk.Operation.manageData({
                name: "Service Name",
                value: formData.service_name,
              })
            )
            .addOperation(
              StellarSdk.Operation.manageData({
                name: "Contact Email",
                value: formData.contact_email,
              })
            )
            .addOperation(
              StellarSdk.Operation.manageData({
                name: "Contact Name",
                value: formData.contact_name,
              })
            )
            .setTimeout(30)
            .build();
          transaction.sign(
            StellarSdk.Keypair.fromSecret(Constants.sourceAccount.secretKey)
          );
          setDisplaySuccessfulComp(true);
          return server.submitTransaction(transaction);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedNetwork === "Production") {
      const data = {
        text: `\nCompany Name: ${formData.company_name}\nService Name: ${formData.service_name}\nContact Email: ${formData.contact_email}\nContact Name: ${formData.contact_name}\nPublic Key: ${publicAndSecretKeyCombined[1][1]} 
        `,
      };
      axios
        .post(
          "https://hooks.slack.com/services/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",
          JSON.stringify(data),
          {
            withCredentials: false,
            transformRequest: [
              (data, headers) => {
                delete headers.post["Content-Type"];
                return data;
              },
            ],
          }
        )
        .then(() => {
          handleClickOpen();
          setTimeout(function () {
            history.push("/tools");
          }, 2000);
        });
    }
  };

  const [displayRegistrationForm, setDisplayRegistrationForm] = useState(false);
  const [displaySuccessfulComp, setDisplaySuccessfulComp] = useState(false);

  useEffect(() => {
    setDisplayRegistrationForm(false);
    if (secretWords) {
      selectedNetwork == "Production" ? setActiveStep(1) : setActiveStep(2)
    }
    setChecked({
      0: false,
      1: false,
    });
  }, [selectedNetwork, secretWords]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [userHaveWrittenSecretWords, setUserHaveWrittenSecretWords] = useState(false);

  const history = useHistory();

  const handleClearUserAnswers = () => {
    setUserChosenAnswer1("");
    setUserChosenAnswer2("");
    setUserChosenAnswer3("");
  }

  return (
    <div>
      <Navbar selectedTitle="tools" />
      <Container maxWidth="xl">
        <Box component="div" style={{ marginTop: "30px" }}>
          <Typography
            className={classes.breadcrumbMain}
            style={{
              fontSize: Constants.fontSize.mainTitle,
            }}
          >
            Create an SSN Account
          </Typography>
        </Box>
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            nonLinear={true}
          >
            {displayPage1 && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <GenerateKeypairsComp
                    label={"Select a Mode"}
                    selectedNetwork={selectedNetwork}
                    handleChangeNetwork={handleChangeNetwork}
                    setLoading={setLoading}
                    generateSecretWords={generateSecretWords}
                    displayButton={true}
                    enableSelectMode={true}
                  />
                </StepContent>
              </Step>
            )}

            {displayPage1 && secretWords && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />

                <StepContent>
                  <SecretWordsComp 
                    secretWords={secretWords} 
                    setUserHaveWrittenSecretWords={setUserHaveWrittenSecretWords}
                    userHaveWrittenSecretWords={userHaveWrittenSecretWords}
                    selectedNetwork={selectedNetwork}
                    handleNext={handleNext}
                  />
                </StepContent>
              </Step>
            )}

            {displayPage2 && checkIfUserChooseCorrectly() && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                  icon={4}
                />
                <StepContent>
                  <EnterSecretWordsComp
                    scrollToBottom={scrollToBottom}
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    register={register}
                    errors={errors}
                    setDisplayPage1={setDisplayPage1}
                    setDisplayPage2={setDisplayPage2}
                    setActiveStep={setActiveStep}
                    setUserEnterSecretWords={setUserEnterSecretWords}
                    setDisplayRegistrationForm={setDisplayRegistrationForm}
                  />
                </StepContent>
              </Step>
            )}

            {(correctSecretWord || selectedNetwork === "Testing") &&
              !loading &&
              secretWords && (
                <Step expanded={true}>
                  <StepLabel
                    icon={selectedNetwork === "Testing" ? 3 : 5}
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <KeypairForCreateAccComp
                      scrollToBottom={scrollToBottom}
                      publicAndSecretKeyCombined={publicAndSecretKeyCombined}
                      userHaveCheckedAll={userHaveCheckedAll}
                      setDisplayRegistrationForm={setDisplayRegistrationForm}
                      setActiveStep={setActiveStep}
                      selectedNetwork={selectedNetwork}
                    />
                  </StepContent>
                </Step>
              )}

            {displayRegistrationForm && !loading && secretWords && (
              <Step expanded={true}>
                <StepLabel
                  icon={selectedNetwork === "Testing" ? 4 : 6}
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <RegistrationFormComp
                    handleSubmitRegistrationForm={handleSubmitRegistrationForm}
                    onSubmitRegisterForm={onSubmitRegisterForm}
                    registerForm={registerForm}
                    errorsRegistrationForm={errorsRegistrationForm}
                    setActiveStep={setActiveStep}
                  />
                </StepContent>
              </Step>
            )}

            {displayPage1 &&
              selectedNetwork === "Production" &&
              secretWords &&
              multipleAnswers && userHaveWrittenSecretWords&& (
                <Step expanded={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.icon,
                      },
                    }}
                  />
                  <StepContent>
                    <MultipleChoiceQuestionsComp
                      userChosenAnswer1={userChosenAnswer1}
                      userChosenAnswer2={userChosenAnswer2}
                      userChosenAnswer3={userChosenAnswer3}
                      handleUserChosenAnswerChange1={
                        handleUserChosenAnswerChange1
                      }
                      handleUserChosenAnswerChange2={
                        handleUserChosenAnswerChange2
                      }
                      handleUserChosenAnswerChange3={
                        handleUserChosenAnswerChange3
                      }
                      questions={questions}
                      multipleAnswers={multipleAnswers}
                      secretWordsToArray={secretWordsToArray}
                      setDisplayPage1={setDisplayPage1}
                      setDisplayPage2={setDisplayPage2}
                      checkIfUserChooseCorrectly={checkIfUserChooseCorrectly}
                      handleNext={handleNext}
                      setActiveStep={setActiveStep}
                      setUserHaveWrittenSecretWords={setUserHaveWrittenSecretWords}
                      handleClearUserAnswers={handleClearUserAnswers}
                      randomizeChoice={randomizeChoice}
                    />
                  </StepContent>
                </Step>
              )}

            {displaySuccessfulComp && (
              <Step expanded={true}>
                <StepLabel
                  icon={selectedNetwork === "Production" ? 7 : 5}
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <AccountCreatedSuccessfulComp
                    secretWords={secretWords}
                    publicAndSecretKeyCombined={publicAndSecretKeyCombined}
                  />
                </StepContent>
              </Step>
            )}
          </Stepper>
        </div>

        {loading && (
          <img
            alt="loading gif"
            src={loadingGif}
            style={{ display: "block", margin: "0 auto", width: "90px" }}
          />
        )}

        <DialogSuccessful
          title={"Account Request Successful"}
          content={
            "Your Account Request will get email confirm from Sabay Settlement Network soon. Thanks You."
          }
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      </Container>
    </div>
  );
};

export default Index;
