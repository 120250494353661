import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "../../index.scss";
import Constants from "../../constants";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "27px",
    fontFamily: Constants.font.regularFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  errorMessage: {
    color: "#F44336",
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  signerInput: {
    boxShadow: "1px 1px 3px #00000029",
    padding: "15px 60px 15px 30px",
    borderRadius: "8px",
    margin: "0",
    width: "100%",
    fontFamily: Constants.font.regularFont,
    color: Constants.fontColor.white,
    border: "none",
    fontSize: Constants.fontSize.standard,
    resize: "none",
    background: Constants.fontColor.darkBlack,
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  rootToggleButton: {
    "& .MuiToggleButton-root": {
      color: Constants.fontColor.white,
      background: Constants.fontColor.darkBlack,
      fontFamily: Constants.font.lightFont,
      padding: "15px 20px 15px 20px",
    },
    "& .Mui-selected": {
      background: Constants.fontColor.blue,
    },
    "& .Mui-selected:hover": {
      background: Constants.fontColor.blue,
    },
  },
}));

const EnterSecretKeyComponent = (props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    typeOfSecret,
    handleSecretChange,
    setActiveStep,
    transactionBuilderPage,
  } = props;

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>
            Signatures: Enter a Secret Key Or Secret Words to sign message
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ paddingTop: "20px" }}
          >
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label}>Signer</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ToggleButtonGroup
                style={{ marginBottom: "15px" }}
                value={typeOfSecret}
                exclusive
                onChange={handleSecretChange}
                aria-label="text alignment"
                className={classes.rootToggleButton}
              >
                <ToggleButton value="Secret Keys" aria-label="left aligned">
                  Secret Keys
                </ToggleButton>
                <ToggleButton value="Secret Words" aria-label="right aligned">
                  Secret Words
                </ToggleButton>
              </ToggleButtonGroup>

              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                style={{ textAlign: "right" }}
              >
                <input
                  className={classes.signerInput}
                  type="text"
                  name="signerSecret"
                  ref={register({
                    required: true,
                  })}
                />
                <div style={{ paddingTop: "15px" }}>
                  {errors?.signerSecret?.type === "required" && (
                    <Typography
                      className={classes.errorMessage}
                      component={"span"}
                      style={{ paddingRight: "15px" }}
                    >
                      Can&apos;t be empty
                    </Typography>
                  )}

                  {errors?.signerSecret?.type === "incorrect" && (
                    <Typography
                      className={classes.errorMessage}
                      component={"span"}
                      style={{ paddingRight: "15px" }}
                    >
                      Incorrect
                    </Typography>
                  )}

                  <Button
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    onClick={() =>
                      transactionBuilderPage
                        ? setActiveStep(5)
                        : setActiveStep(4)
                    }
                  >
                    Sign
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default EnterSecretKeyComponent;
