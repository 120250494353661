import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Balance from "./accountTabsBalance";
// import Transactions from "./accountTabsTransaction";
import Signing from "./accountTabsSigning";
import AccountDetail from "./accountTabsDetail";
import Flags from "./accountTabsFlags";
import Payment from "./accountTabsPayment";
import { useHistory } from "react-router-dom";

const Index = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);

  function TabItem({ index, title }) {
    let hashName = "";
    switch (index) {
      case 1:
        hashName = "#balance";
        break;
      case 2:
        hashName = "#payment";
        break;
      case 3:
        hashName = "#signing";
        break;
      case 4:
        hashName = "#account-details";
        break;
      case 5:
        hashName = "#flags";
        break;
    }
    function activeTabClass(i, iTab) {
      if (i === iTab) {
        return "nav-tab-link-active";
      } else {
        return "nav-tab-link-for-tab-inactive";
      }
    }

    function tabClicked(i, iTab) {
      if (i !== iTab) {
        setActiveTab(i);
      }
    }

    return (
      <NavItem style={{ padding: 0, borderRadius: "5px 5px 0px 0px" }}>
        <NavLink
          className={clsx("nav-tab-link", activeTabClass(index, activeTab))}
          onClick={(e) => {
            tabClicked(index, activeTab);
            window.history.pushState("", "", `${hashName}`);
          }}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  }
  useEffect(() => {
    switch (history.location.hash) {
      case "#balance":
        setActiveTab(1);
        break;
      case "#payment":
        setActiveTab(2);
        break;
      case "#signing":
        setActiveTab(3);
        break;
      case "#account-details":
        setActiveTab(4);
        break;
      case "#flags":
        setActiveTab(5);
        break;
    }
  }, []);

  return (
    <div>
      <Nav tabs className="nav-tab">
        <TabItem index={1} title={translate("Balance")} />
        {/* <TabItem index={2} title={translate("Transactions")} /> */}
        <TabItem index={2} title={translate("Payment")} />
        <TabItem index={3} title={translate("Signing")} />
        <TabItem index={4} title={translate("Account Details")} />
        <TabItem index={5} title={translate("Flags")} />
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Balance />
        </TabPane>
        {/* <TabPane tabId={2}>
          <Transactions />
        </TabPane> */}
        <TabPane tabId={2}>
          <Payment />
        </TabPane>
        <TabPane tabId={3}>
          <Signing />
        </TabPane>
        <TabPane tabId={4}>
          <AccountDetail />
        </TabPane>
        <TabPane tabId={5}>
          <Flags />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Index;
