import React from "react";
import { Card, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import CardLabel from "../utilities/cardLabel";

const Index = ({ operations }) => {
  const { t: translate } = useTranslation();

  function HeadComp() {
    return (
      <tr>
        <th className="text-title-dark">{translate("ID")}</th>
        <th className="text-title-dark">{translate("Type")}</th>
        <th className="text-title-dark">{translate("Details")}</th>
      </tr>
    );
  }

  function RowComp({ item }) {
    return (
      <tr>
        <th className="text-para-white">{item.transactionID}</th>
        <td className="text-para-white">{item.type}</td>
        <td className="text-para-white">{JSON.stringify(item.details)}</td>
      </tr>
    );
  }

  return (
    <div className="card-v-padding-16">
      <Card body>
        <CardLabel title={translate("Operations")} />
        <Table hover borderless responsive className="margin-top-16">
          <thead>
            <HeadComp />
          </thead>
          <tbody>
            {operations.map((item, key) => (
              <RowComp key={key} item={item} />
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Index;
