import React from "react";
import { TextField, makeStyles, Typography } from "@material-ui/core";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "#1E1E26",
    borderRadius: "6px",
  },
  content: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
  },
  textInput: {
    color: "white",
  },
  error: {
    color: "#e53935",
    fontSize: Constants.fontSize.extraSmall,
  },
}));

const TextFormField = (props) => {
  const classes = useStyles();
  const {
    placeholder,
    name,
    type,
    value,
    handleChange,
    errText,
    maxLength,
    refForReactHookForm,
    handleBlur,
    id,
    defaultValue,
  } = props;

  return (
    <div className={classes.content}>
      <TextField
        id={id}
        error={errText ? true : false}
        placeholder={placeholder}
        name={name}
        onBlur={handleBlur}
        type={type}
        value={value}
        className={classes.textField}
        onChange={handleChange}
        inputRef={refForReactHookForm}
        variant="outlined"
        defaultValue={defaultValue}
        InputProps={{
          className: classes.textInput,
        }}
        inputProps={{ maxLength: maxLength ? 12 : 10000 }}
      />
      <Typography className={classes.error}>{errText}</Typography>
    </div>
  );
};

export default TextFormField;
