import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import TextFormField from "../../../components/textfield/textfield";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  gridTextCenter: {
    height: "52px",
    marginTop: "16px",
    alignItems: "center",
  },
  defaultPadding: {
    padding: "10px 15px",
  },
  border: {
    borderTop: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    padding: "10px 15px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    textTransform: "Capitalize",
  },
  borderRight: {
    borderRight: "2px solid #272831",
    backgroundColor: "red",
  },
  rootToggleButton: {
    "& .MuiToggleButton-root": {
      color: "#FFF",
      background: "#1E1E26",
      fontFamily: "OpenSans-Light",
      padding: "15px 20px 15px 20px",
    },
    "& .Mui-selected": {
      background: "#342EAD",
    },
    "& .Mui-selected:hover": {
      background: "#342EAD",
    },
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const {
    allowTrust,
    handleAllowTrustTextChange,
    handleAllowTrustAuthorizeChange,
    trustorErrMsg,
    index,
  } = props;

  return (
    <Grid container className={`${classes.defaultPadding} ${classes.border}`}>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Trustor</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          placeholder="Example: GATYCTIG2VZI2537PLD43QOO3ZBWIOLDT5JPWYPFQRJJFTMPUQKQ7NJED43QOO3ZBWIOLDT5JPWYPFQRJJ"
          name="trustor"
          value={allowTrust.trustor}
          type="text"
          disableUnderline={true}
          handleChange={handleAllowTrustTextChange(index)}
          errText={trustorErrMsg[index]}
          maxLength={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Asset Code</Typography>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10} className={classes.gridItem}>
        <TextFormField
          name="assetCode"
          value={allowTrust.assetCode}
          type="text"
          handleChange={handleAllowTrustTextChange(index)}
          disableUnderline={true}
          maxLength={false}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={2}
        className={`${classes.gridTextCenter} ${classes.gridItem}`}
      >
        <Typography className={classes.text}>Authorize</Typography>
      </Grid>
      <Grid item xs={12} sm={10} className={classes.gridItem}>
        <ToggleButtonGroup
          value={allowTrust.authorize}
          exclusive
          onChange={handleAllowTrustAuthorizeChange(index)}
          aria-label="text alignment"
          className={classes.rootToggleButton}
        >
          <ToggleButton
            value={true}
            aria-label="left aligned"
            className={`${classes.btn} ${classes.borderRight}`}
          >
            True
          </ToggleButton>
          <ToggleButton
            value={false}
            aria-label="right aligned"
            className={`${classes.btn} ${classes.borderRight}`}
          >
            False
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  allowTrust: PropTypes.object,
  handleAllowTrustTextChange: PropTypes.func,
  handleAllowTrustAuthorizeChange: PropTypes.func,
  trustorErrMsg: PropTypes.array,
  index: PropTypes.number,
};

export default Index;
