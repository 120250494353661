import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "../../index.scss";
import Constants from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "27px",
    fontFamily: Constants.font.regularFont,
  },
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "0 0 0 0",
    fontFamily: Constants.font.lightFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  innerContainer: {
    padding: "18px",
    border: "1px solid #707070",
    borderRadius: "6px",
  },
  line: {
    border: "1px solid #1E1F2C",
    marginTop: "-5px",
    left: 0,
    width: "100%",
  },
}));

const TransactionOverviewComp = (props) => {
  const classes = useStyles();

  const {
    transactionOverview,
    title,
    displayTopData,
    signature,
    displaySignature,
    enableMarginTop,
  } = props;

  let allOperationsToDisplayBelow = [];
  transactionOverview._operations.map((data, index) => {
    allOperationsToDisplayBelow.push(
      data.type.replace(/([A-Z])/g, " $1").trim()
    );
    return null;
  });

  const mySet = new Set(allOperationsToDisplayBelow);
  allOperationsToDisplayBelow = [...mySet];

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{
          position: "relative",
          marginTop: enableMarginTop ? "30px" : "0px",
        }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>{title}</Typography>

          <hr className={classes.line} style={{ position: "absolute" }} />
          {displayTopData && (
            <div style={{ padding: "18px", borderRadius: "6px" }}>
              <Grid
                container
                className={classes.root}
                spacing={4}
                style={{ paddingTop: "20px" }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>
                    Source Account
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Typography
                    className={classes.subText}
                    style={{ wordBreak: "break-all" }}
                  >
                    {transactionOverview._source}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Fee</Typography>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Typography className={classes.subText}>
                    {parseInt(transactionOverview._fee).toLocaleString()}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography className={classes.label}>Operations</Typography>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Typography className={classes.subText}>
                    {transactionOverview._operations.length}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}

          {transactionOverview._operations.map((data, index) => {
            if (data.type === "payment") {
              return (
                <div
                  key={index}
                  style={{
                    padding: "18px",
                    marginTop: "23px",
                    border: "1px solid #707070",
                    borderRadius: "6px",
                  }}
                >
                  <Typography className={classes.mainText}>Payment</Typography>

                  <hr className={classes.line} />

                  <Grid
                    container
                    className={classes.root}
                    spacing={4}
                    style={{ paddingTop: "20px" }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.label}>Amount</Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        className={classes.subText}
                        style={{ wordBreak: "break-all" }}
                      >
                        {parseInt(data.amount).toLocaleString()}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.label}>
                        Destination
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        className={classes.subText}
                        style={{ wordBreak: "break-all" }}
                      >
                        {data.destination}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            }

            if (data.type === "manageBuyOffer") {
              return (
                <div
                  style={{
                    padding: "18px",
                    marginTop: "23px",
                    border: "1px solid #707070",
                    borderRadius: "6px",
                  }}
                  key={index}
                >
                  <Typography className={classes.mainText}>
                    Manage Buy Offer
                  </Typography>

                  <hr className={classes.line} />

                  <Grid
                    container
                    className={classes.root}
                    spacing={4}
                    style={{ paddingTop: "20px" }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.label}>
                        Buy Amount
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        className={classes.subText}
                        style={{ wordBreak: "break-all" }}
                      >
                        {parseInt(data.buyAmount).toLocaleString()}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.label}>Price</Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        className={classes.subText}
                        style={{ wordBreak: "break-all" }}
                      >
                        {parseInt(data.price).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            }
            return null;
          })}
          <div style={{ paddingTop: "18px" }} />
          <span className={classes.mainText}>
            {allOperationsToDisplayBelow.length > 1
              ? "Operations: "
              : "Operation: "}
          </span>
          {allOperationsToDisplayBelow.map((data, index) => {
            return (
              <span
                style={{ textTransform: "capitalize" }}
                className={classes.mainText}
                key={index}
              >
                {allOperationsToDisplayBelow[index + 1] ? `${data}, ` : data}
              </span>
            );
          })}
          <div style={{ paddingTop: "18px" }} />

          {displaySignature && (
            <div className={classes.innerContainer}>
              <Typography
                className={classes.mainText}
                style={{ display: "flex", alignContent: "center" }}
              >
                {signature.length > 0 ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      color: Constants.fontColor.success,
                      fontSize: "30px",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{
                      color: Constants.fontColor.failure,
                      fontSize: "30px",
                      marginRight: "10px",
                    }}
                  />
                )}
                {signature.length > 0 ? (
                  <span style={{ color: Constants.fontColor.success }}>
                    Valid Signature
                  </span>
                ) : (
                  <span style={{ color: Constants.fontColor.failure }}>
                    Invalid Signature
                  </span>
                )}
              </Typography>
              <hr className={classes.line} />

              <Grid
                container
                className={classes.root}
                spacing={4}
                style={{ paddingTop: "20px" }}
              >
                {signature.map((data, index) => {
                  if (index % 2 === 0) {
                    return (
                      <Grid item xs={12} sm={3} key={index}>
                        <Typography className={classes.label}>
                          <span style={{ marginLeft: "8px" }}> {data} </span>
                        </Typography>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={12} sm={9} key={index}>
                        <Typography
                          className={classes.subText}
                          style={{ wordBreak: "break-all" }}
                        >
                          {data}
                        </Typography>
                      </Grid>
                    );
                  }
                })}
                {signature.length === 0 && (
                  <>
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.label}>
                        <span style={{ marginLeft: "8px" }}> Signature </span>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        className={classes.subText}
                        style={{ wordBreak: "break-all" }}
                      >
                        None
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default TransactionOverviewComp;
