import React, { useEffect } from "react";
import { Card, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_TRANSACTIONS } from "../../graphql/query-mutation/ledger";
import InfoAction from "../../components/utilities/infoAction";
import CardLabel from "../../components/utilities/cardLabel";
import Constants from "../../constants";
import EnvironmentLabel from "../../graphql/client";

const Index = ({ ledger = null }) => {
  const { t: translate } = useTranslation();
  const { label } = useParams();
  const history = useHistory();

  function RowComp({ tran }) {
    const hash = tran.transactionHash;
    const account = tran.account;
    const count = tran.operationCount;
    return (
      <tr>
        <th
          className="text-title-link"
          onClick={() => history.push(`/transaction/${label}/${hash}`)}
        >
          {hash}
        </th>
        <td
          className="text-title-link"
          onClick={() => history.push(`/account/${label}/${account}`)}
        >
          {account}
        </td>
        <td className="text-para-white">{count}</td>
      </tr>
    );
  }

  function TableComp({ trans }) {
    return (
      <Table hover borderless responsive className="margin-top-16">
        <thead>
          <tr>
            <th className="text-title-dark">{translate("Transaction Hash")}</th>
            <th className="text-title-dark">{translate("Source Account")}</th>
            <th className="text-title-dark">{translate("Operation Count")}</th>
          </tr>
        </thead>
        <tbody>
          {trans.map((tran, index) => (
            <RowComp key={index} tran={tran} />
          ))}
        </tbody>
      </Table>
    );
  }

  function ListComp() {
    const client = EnvironmentLabel.getClient(label);
    const sequence = ledger.sequence;
    const options = {
      client: client,
      variables: { number: sequence, limit: Constants.limitTran },
      fetchPolicy: "network-only",
    };
    const [getTrans, { loading, data, error }] = useLazyQuery(
      QUERY_TRANSACTIONS,
      options
    );
    const trans = data?.ledger[0]?.transactions;
    useEffect(() => {
      getTrans();
      return () => {};
    }, [getTrans]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getTrans} />;
    }
    if (!trans) {
      return <InfoAction description={translate("No Transactions")} />;
    }
    return <TableComp trans={trans} />;
  }

  return (
    <div className="card-v-padding-16">
      <Card body>
        <CardLabel title={translate("Transactions")} />
        <ListComp />
      </Card>
    </div>
  );
};

export default Index;
