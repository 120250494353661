import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_INFO } from "../../graphql/query-mutation/ledger";
import Navbar from "../../components/navigation";
import InfoAction from "../../components/utilities/infoAction";
import AccountInfo from "../../components/account/accountInfo";
import AccountTabs from "../../components/account/accountTabs";
import AccoutNotFound from "../../components/account/accountNotFound";
import EnvironmentLabel from "../../graphql/client";
import Container from "@material-ui/core/Container";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();

  function MainComp({ data }) {
    return (
      <div>
        <Navbar selectedTitle="dashboard" />
        <Container maxWidth="xl">
          <Row className="row-h-padding">
            <Col md="12" className={"no-gutters"}>
              <AccountInfo />
            </Col>
          </Row>
          <Row className="row-h-padding">
            <Col md="12" className={"no-gutters"}>
              <AccountTabs />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_INFO,
      options
    );
    const object = data?.account;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <AccoutNotFound />;
    }
    if (!object) {
      return <AccoutNotFound />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
