import React from "react";
import ServerLabel from "../utilities/serverLabel";
import { Card, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CardLabel from "../utilities/cardLabel";
import moment from "moment";

const Index = ({ ledger = null, ...rest }) => {
  const { t: translate } = useTranslation();
  const { label, sequence } = useParams();
  const history = useHistory();

  const dateUTC = ledger.createdAt;
  const date = moment.utc(dateUTC).format("YYYY-MM-DD hh:mm:ss A");
  const stillUtc = moment.utc(date).toDate();
  const localTime = moment(stillUtc).local().format("YYYY-MM-DD hh:mm:ss A");

  function TableComp({ title, data }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-white">{data}</td>
      </tr>
    );
  }

  function TableClickComp({ title, data, onClick = () => null }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-link" onClick={onClick}>
          {data}
        </td>
      </tr>
    );
  }

  return (
    <div className="card-v-padding-16">
      <Card body>
        <ServerLabel label={label} />
        <CardLabel title={`${translate("Ledgers")} ${sequence}`} />
        <Table hover borderless responsive className="margin-top-16">
          <tbody>
            <TableComp title={translate("Time")} data={localTime} />
            <TableComp title={translate("Hash")} data={ledger.ledgerHash} />
            <TableClickComp
              title={translate("Prev Hash")}
              data={ledger.previousLedgerHash}
              onClick={() =>
                history.push(`/ledger/${label}/${ledger.sequence - 1}`)
              }
            />
            <TableComp
              title={translate("Operations")}
              data={ledger.transactionCount}
            />
            <TableComp
              title={translate("Transactions Failed")}
              data={ledger.failedTransactionCount}
            />
            <TableComp
              title={translate("Max Transactions")}
              data={ledger.maxTxSetSize}
            />
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Index;
