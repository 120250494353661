import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import { useMediaQuery } from "@material-ui/core";
import ReactSelect from "react-select";
import Grid from "@material-ui/core/Grid";
import Constants from "../../constants";
import Button from "../button/button";
const useStyles = makeStyles((theme) => ({
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "0 50px 0 0",
    fontFamily: Constants.font.lightFont,
    alignSelf: "center",
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
  },

  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
}));

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 670,
      lg: 1280,
      xl: 1920,
    },
  },
});

const GenerateKeypairs = (props) => {
  const {
    label,
    selectedNetwork,
    handleChangeNetwork,
    displayButton,
    setLoading,
    generateSecretWords,
    enableSelectMode = false
  } = props;

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const options = [
    { value: "Testing", label: enableSelectMode ? "Easy" : "Testing" },
    { value: "Production", label: enableSelectMode ? "Secured" : "Production" },
  ];

  const customStyles = {
    container: (defaultStyle) => ({
      ...defaultStyle,
      display: "inline-block",
      paddingRight: "15px",
    }),

    control: (defaultStyle, state) => ({
      ...defaultStyle,
      width: "170px",
      border: 0,
      boxShadow: "none",
      padding: "11px 5px",
      backgroundColor: Constants.fontColor.darkBlack,
      cursor: "pointer",
    }),

    option: (defaultStyle, state) => ({
      ...defaultStyle,
      color: "#000000",
      textAlign: "center",
      backgroundColor: state.isSelected
        ? Constants.fontColor.white
        : Constants.fontColor.white,
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.07) !important",
      },
      cursor: "pointer",
    }),

    menu: (base) => ({
      ...base,
      margin: 0,
    }),
  };


  return (
    <Box component="div" className={classes.container}>
      <div style={{ padding: "20px 30px" }}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item style={{ display: "flex" }}>
            <Typography
              className={classes.subText}
              style={{ padding: isSm && "0 0 15px 0" }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item>
            <Box component="div" style={{ display: "flex" }}>
              <Grid container className={classes.root} spacing={1}>
                <Grid item sm={6}>
                  <ReactSelect
                    isSearchable={false}
                    styles={customStyles}
                    value={{
                      label: enableSelectMode ? (selectedNetwork == "Testing" ? "Easy" : selectedNetwork == "Production" ? "Secured" : "Select Mode") : selectedNetwork,
                      value: selectedNetwork,
                    }}
                    onChange={(e) => handleChangeNetwork(e)}
                    options={options}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "white",
                        primary50: "white",
                        neutral80: "white",
                      },
                    })}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  {displayButton && (
                    <Button
                      text={"Generate"}
                      handleClickButton={() => {
                        setLoading(true);
                        generateSecretWords();
                      }}
                    >
                      Generate
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default GenerateKeypairs;
