import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_TRANSACTION } from "../../graphql/query-mutation/ledger";
import Navbar from "../../components/navigation";
import InfoAction from "../../components/utilities/infoAction";
import TransactionInfo from "../../components/transactions/transactionInfo";
import TransactionOperation from "../../components/transactions/transactionOperation";
import TransactionNotFound from "../../components/transactions/transactionNotFound";
import EnvironmentLabel from "../../graphql/client";
import Container from "@material-ui/core/Container";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, hash } = useParams();

  function SectionComp({ tran }) {
    return (
      <div>
        <Navbar selectedTitle="dashboard" />
        <Container maxWidth="xl">
          <Row className="row-h-padding">
            <Col md="9" className={"no-gutters"}>
              <TransactionInfo tran={tran} />
              <TransactionOperation operations={tran?.operations} />
            </Col>
            <Col md="3" className={"no-gutters"} />
          </Row>
        </Container>
      </div>
    );
  }

  function TranComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { hash: hash },
      fetchPolicy: "network-only",
    };
    const [getTran, { loading, data, error }] = useLazyQuery(
      QUERY_TRANSACTION,
      options
    );
    const tran = data?.transaction;

    useEffect(() => {
      getTran();
      return () => {};
    }, [getTran]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <TransactionNotFound />;
    }
    if (!tran) {
      return <TransactionNotFound />;
    }

    return <SectionComp tran={tran} />;
  }

  return <TranComp />;
};

export default Index;
