import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_LEDGER } from "../../graphql/query-mutation/ledger";
import Navbar from "../../components/navigation";
import InfoAction from "../../components/utilities/infoAction";
import LedgerInfo from "../../components/ledgers/ledgerInfo";
import LedgerDescription from "../../components/ledgers/ledgerDescription";
import LedgerTransaction from "../../components/ledgers/ledgerTransaction";
import LedgerNotFound from "../../components/ledgers/ledgerNotFound";
import EnvironmentLabel from "../../graphql/client";
import Container from "@material-ui/core/Container";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, sequence } = useParams();

  function SectionComp({ ledger }) {
    return (
      <div>
        <Navbar selectedTitle="dashboard" />
        <Container maxWidth="xl">
          <Row className="row-h-padding">
            <Col md="9" className={"no-gutters"}>
              <LedgerDescription ledger={ledger} />
              <LedgerTransaction ledger={ledger} />
            </Col>
            <Col md="3" className={"no-gutters"}>
              <LedgerInfo ledger={ledger} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  function LedgerComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { number: sequence },
      fetchPolicy: "network-only",
    };
    const [getLedger, { loading, data, error }] = useLazyQuery(
      QUERY_LEDGER,
      options
    );
    const ledger = data?.ledger[0];

    useEffect(() => {
      getLedger();
      return () => {};
    }, [getLedger]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <LedgerNotFound />;
    }
    if (!ledger) {
      return <LedgerNotFound />;
    }

    return <SectionComp ledger={ledger} />;
  }

  return <LedgerComp />;
};

export default Index;
