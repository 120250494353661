import React from "react";
import Navbar from "../../components/navigation";
import { Row, Col } from "reactstrap";
import Container from "@material-ui/core/Container";

const Index = (props) => {
  return (
    <div>
      <Navbar selectedTitle="learn" />
      <Container maxWidth="xl">
        <Row className="row-h-padding row-v-padding">
          <Col md="12" className={"no-gutters text-center col-v-padding-16"}>
            <div className="color-notfound text-not-found">Coming Soon...</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
