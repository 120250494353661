import React from "react";
import { Typography, makeStyles, Grid, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import TextFormField from "../../../components/textfield/textfield";
import SelectOption from "../../../components/select/select";
import MainButton from "../../../components/button/button";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      borderBottom: "none !important",
      color: "#767676 !important",
    },
    "& .MuiTableCell-head": {
      padding: "16px !important",
    },
    "& .MuiTableCell-body": {
      padding: "16px !important",
    },
  },
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  gridTextCenter: {
    height: "52px",
    marginTop: "16px",
    alignItems: "center",
  },
  defaultPadding: {
    padding: "20px 30px",
  },
  left: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "20px",
  },
  border: {
    borderTop: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    padding: "10px 15px",
  },
  grid: {
    border: "1px solid #444",
    borderRadius: "6px",
    padding: "5px 0px",
  },
  removeIcon: {
    backgroundColor: "#1E1E26",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    padding: 0,
    "&:hover": {
      backgroundColor: "#000000",
      boxShadow: "none",
    },
  },
}));

const options = [
  { value: "Weight Number", label: "Weight Number" },
  { value: "200", label: "200" },
  { value: "50", label: "50" },
  { value: "10", label: "10" },
];

const Index = (props) => {
  const classes = useStyles();
  const {
    singerConfig,
    handleAddSigner,
    handleChangeWeightText,
    handleChangeWeight,
    handleRemoveSigner,
    index,
  } = props;

  const handleClickAddButton = (event) => {
    event.preventDefault();
    handleAddSigner(index);
  };

  const handleRemoveButton = (objIndex) => {
    handleRemoveSigner(index, objIndex);
  };

  return (
    <div>
      <Grid container className={`${classes.defaultPadding} ${classes.border}`}>
        <Grid item xs={12}>
          <Typography className={classes.title} style={{ padding: "10px 0px" }}>
            All Signers
          </Typography>
        </Grid>
        {singerConfig?.map((item, objIndex) => (
          <Grid
            container
            key={objIndex}
            className={classes.grid}
            style={{
              marginTop: objIndex !== 0 && "16px",
              padding: singerConfig.length > 1 && "0px 0px 10px 0px",
            }}
          >
            {singerConfig.length > 1 && (
              <Grid item xs={12} sm={12} style={{ height: "30px" }}>
                <div
                  style={{
                    padding: "8px 8px",
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => handleRemoveButton(objIndex)}
                    className={`${classes.center} ${classes.removeIcon}`}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      color="white"
                      style={{ fontSize: 12, color: "#767676" }}
                    />
                  </IconButton>
                </div>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={`${classes.gridTextCenter} ${classes.gridItem}`}
            >
              <Typography className={classes.text}>Public Key</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
              lg={10}
              className={classes.gridItem}
              style={{ marginLeft: "-15px" }}
            >
              <TextFormField
                placeholder="GATYCTIG2VZI2537PLD43QOO3ZBWIOLDT5JPWYPFQRJJFTMPUQKQ7NJED43QOO3ZBWIOLDT5JPWYPFQRJJ"
                name="inflationDest"
                value={item.inflationDest}
                type="text"
                disableUnderline={true}
                handleChange={handleChangeWeightText(index, objIndex)}
                errText={item.errMsg}
                maxLength={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={`${classes.gridTextCenter} ${classes.gridItem}`}
            >
              <Typography className={classes.text}>Weight</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
              lg={10}
              className={classes.gridItem}
              style={{ marginLeft: "-15px" }}
            >
              <SelectOption
                options={options}
                objIndex={objIndex}
                index={index}
                selectedOption={{
                  label: item.weight,
                  value: item.weight,
                }}
                borderRadius="5px"
                borderColor="white"
                handleChangeValue={handleChangeWeight}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container className={`${classes.left}`}>
          <MainButton text="Add" handleClickButton={handleClickAddButton} />
        </Grid>
      </Grid>
    </div>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  singerConfig: PropTypes.array,
  destinationErrMsg: PropTypes.array,
  handleChangeWeight: PropTypes.func,
  handleChangeWeightText: PropTypes.func,
  handleAddSigner: PropTypes.func,
  handleRemoveSigner: PropTypes.func,
  index: PropTypes.number,
};

export default Index;
