import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-boost";
import Constants from "../../constants";

const production = new ApolloClient({
  uri: Constants.baseUrl.production,
  cache: new InMemoryCache(),
});

const master = new ApolloClient({
  uri: Constants.baseUrl.master,
  cache: new InMemoryCache(),
});

const testing = new ApolloClient({
  uri: Constants.baseUrl.testing,
  cache: new InMemoryCache(),
});

const environmentLabel = {
  getClient: (label) => {
    if (label === "production") {
      return production;
    } else if (label === "testing") {
      return testing;
    } else if (label === "master"){
      return master;
    } else {
      return null;
    }
  },
  getColor: (label) => {
    if (label === "production") {
      return "#342EAD";
    } else if (label === "testing") {
      return "#00BCD4";
    } else if (label === "master") {
      return "#4000d4";
    } else {
      return "#ffffff";
    }
  },
};

export default environmentLabel;
