import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Button from "@material-ui/core/Button";
import DialogSuccessful from "../utilities/dialogSuccessful";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  containerKeypairs: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  table: {
    "& .MuiTable-root": {
      borderCollapse: "separate !important",
      borderSpacing: "0 5px !important",
    },
    "& .MuiTableRow-root .MuiTableCell-root": {
      borderBottom: "none !important",
    },
    "& .MuiTableCell-root": {
      fontSize: Constants.fontSize.standard,
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  tableDataRoot: {
    "& .tableData": {
      borderBottom: "1px solid #767676",
      padding: "7px 7px 19px 15px",
      fontSize: "14px",
    },

    "&:last-child .tableData": {
      borderBottom: "none",
    },
  },
}));

const KeypairComp = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        component="div"
        className={classes.containerKeypairs}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>
            Make sure to physically write down your keypair and keep it safe.
            Check the box at the end to confirm that you have written them down.
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <TableContainer className={classes.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontFamily: Constants.font.semiBoldFont }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: Constants.font.semiBoldFont }}
                  >
                    Public Key
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: Constants.font.semiBoldFont }}
                  >
                    Secret Key
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: Constants.font.semiBoldFont }}
                  >
                    Check
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  {props.publicAndSecretKeyCombined.map((data, index) => (
                    <TableCell key={index} style={{ margin: 0, padding: 0 }}>
                      {data.map((item, i) => {
                        return (
                          <div key={i} className={classes.tableDataRoot}>
                            <p className="tableData">{item}</p>
                          </div>
                        );
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {props.userHaveCheckedAll && (
            <div style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => {
                  handleClickOpen();
                  setTimeout(function () {
                    history.push("/tools");
                  }, 2000);
                }}
              >
                Done
              </Button>
            </div>
          )}
          <DialogSuccessful
            title={"Keypair Generate Successful"}
            content={
              "Your Keypair can be use in Sabay Settlement Network. Thanks You."
            }
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        </div>
      </Box>
    </div>
  );
};

export default KeypairComp;
