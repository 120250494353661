import React from "react";
import { Card, CardText } from "reactstrap";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../constants";

const Index = (props) => {
  return (
    <div>
      <Card body style={{ border: "0" }}>
        <div style={{ paddingBottom: "20px" }}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="lg"
            style={{ color: Constants.fontColor.white, marginRight: "5px" }}
          />
          <span style={{ color: Constants.fontColor.white }}>
            {" "}
            What’s SSN Explorer?{" "}
          </span>
        </div>

        <CardText className="text-para-white">
          This site allows you to explore the SSN blockchain, learn using our
          test network and use tools to perform actions on the production
          network.
        </CardText>
        <CardText className="text-para-white">
          Secure Settlement Network (SSN) is an open, neutral and secure payment
          authorization ledger; linking payment providers to merchants and
          customers.
        </CardText>
        <CardText className="text-para-white">
          Visit{" "}
          <a
            href="https://ssn.digital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ssn.digital
          </a>{" "}
          for more information.
        </CardText>
      </Card>
    </div>
  );
};

export default Index;
