import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextFormField from "../textfield/textfieldWithOutline";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    padding: "0 0 0 0",
    fontFamily: Constants.font.lightFont,
    alignSelf: "center",
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  verticleCenter: {
    display: "flex",
    alignContent: "center",
  },
}));

const RegistrationFormComp = (props) => {
  const classes = useStyles();
  const {
    handleSubmitRegistrationForm,
    onSubmitRegisterForm,
    registerForm,
    errorsRegistrationForm,
    setActiveStep,
  } = props;

  console.log(errorsRegistrationForm);

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.mainText}>
            Fill out the information below to let us know who you are.
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <form
            autoComplete="off"
            onSubmit={handleSubmitRegistrationForm(onSubmitRegisterForm)}
            style={{ paddingTop: "20px" }}
          >
            <Grid
              container
              className={classes.root}
              spacing={4}
              style={{ paddingTop: "20px" }}
            >
              <Grid item xs={12} sm={3} className={classes.verticleCenter}>
                <Typography className={classes.subText}>
                  Company Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextFormField
                  name="company_name"
                  type="text"
                  refForReactHookForm={registerForm({
                    required: true,
                  })}
                  errText={
                    errorsRegistrationForm?.company_name?.type === "required" &&
                    "Company Name Field is Required"
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3} className={classes.verticleCenter}>
                <Typography className={classes.subText}>
                  Contact Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextFormField
                  name="contact_name"
                  type="text"
                  refForReactHookForm={registerForm({
                    required: true,
                  })}
                  errText={
                    errorsRegistrationForm?.contact_name?.type === "required" &&
                    "Contact Name Field is Required"
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3} className={classes.verticleCenter}>
                <Typography className={classes.subText}>
                  Service Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextFormField
                  name="service_name"
                  type="text"
                  refForReactHookForm={registerForm({
                    required: true,
                  })}
                  errText={
                    errorsRegistrationForm?.service_name?.type === "required" &&
                    "Service Name Field is Required"
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3} className={classes.verticleCenter}>
                <Typography className={classes.subText}>
                  Contact email
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextFormField
                  name="contact_email"
                  type="text"
                  refForReactHookForm={registerForm({
                    required: true,
                    pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
                  })}
                  errText={
                    errorsRegistrationForm?.contact_email?.type === "required"
                      ? "Contact Email Field is Required"
                      : errorsRegistrationForm?.contact_email?.type ===
                          "pattern" && "Contact Email is Invalid"
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignContent: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  onClick={() => setActiveStep(4)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </div>
  );
};

export default RegistrationFormComp;
