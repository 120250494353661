import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./containers/dashboard";
import Learn from "./containers/learn";
import Tools from "./containers/tools";
// import Status from "./containers/status";
import LedgerDetail from "./containers/ledgers";
import TransactionDetail from "./containers/transactions";
import AccountDetail from "./containers/account";
import NoMatch from "./containers/no-match";
import KeypairGenerator from "./containers/keypair-generator";
import CreateSsnAccount from "./containers/create-ssn-account";
import SignTransaction from "./containers/sign-transaction";
import BuildTransaction from "./containers/build-transaction";
import XdrViewer from "./containers/xdr-viewer";
import Receipt from "./containers/receipt";
import i18next from "./i18next";

export default function () {
  useEffect(() => {
    const language =
      localStorage.getItem("language") === null
        ? "en"
        : localStorage.getItem("language");
    i18next.changeLanguage(language);
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/learn" component={Learn} />
        <Route path="/tools" component={Tools} />
        {/* <Route path="/status" component={Status} /> */}
        <Route path="/keypair-generator" component={KeypairGenerator} />
        <Route path="/create-ssn-account" component={CreateSsnAccount} />
        <Route path="/transaction-signer" component={SignTransaction} />
        <Route path="/xdr-viewer" component={XdrViewer} />

        <Route path="/account/:label/:key" render={() => <AccountDetail />} />
        <Route
          path="/ledger/:label/:sequence"
          render={() => <LedgerDetail />}
        />
        <Route
          path="/transaction/:label/:hash"
          render={() => <TransactionDetail />}
        />
        <Route path="/build-transaction" component={BuildTransaction} />
        <Route path="/payments/:label/:hash" component={Receipt} />

        <Route path="*" render={() => <NoMatch />} />
      </Switch>
    </Router>
  );
}
