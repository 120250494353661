import React, { useEffect } from "react";
import { Card, Table, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_INFO } from "../../graphql/query-mutation/ledger";
import ServerLabel from "../utilities/serverLabel";
import CardLabel from "../utilities/cardLabel";
import InfoAction from "../utilities/infoAction";
import EnvironmentLabel from "../../graphql/client";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();

  function RowComp({ title, data }) {
    return (
      <tr>
        <th className="text-title-dark">{title}</th>
        <td className="text-para-white">{data}</td>
      </tr>
    );
  }

  function MainComp({ data }) {
    return (
      <div className="card-v-padding-16">
        <Card body className="card-v-padding-16">
          <ServerLabel label={label} />
          <Row>
            <Col md="12" className={"no-gutters"}>
              <CardLabel title={translate("Account Information")} />
            </Col>
          </Row>
          <Row>
            <Col md="9" className={"no-gutters"}>
              <Row className="row-h-padding">
                <Table hover borderless responsive className="margin-top-16">
                  <tbody>
                    <RowComp title={translate("Public Key")} data={data.id} />
                    <RowComp
                      title={translate("Home Domain")}
                      data={data.homeDomain}
                    />
                    <RowComp
                      title={translate("Sequence")}
                      data={data.sequence}
                    />
                    <RowComp
                      title={translate("Native Balance")}
                      data={parseInt(data.nativeBalance).toLocaleString()}
                    />
                  </tbody>
                </Table>
              </Row>
            </Col>
            <Col md="3" className={"no-gutters"}>
              <Row className="row-h-padding">
                <Table hover borderless responsive className="margin-top-16">
                  <tbody>
                    <RowComp
                      title={translate("Master Weight")}
                      data={data.masterWeight}
                    />
                    <RowComp
                      title={translate("Low Threshold")}
                      data={data.lowThreshold}
                    />
                    <RowComp
                      title={translate("Medium Threshold")}
                      data={data.mediumThreshold}
                    />
                    <RowComp
                      title={translate("High Threshold")}
                      data={data.highThreshold}
                    />
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_INFO,
      options
    );
    const object = data?.account;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getData} />;
    }
    if (!object) {
      return <InfoAction description={translate("No Account Information")} />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
