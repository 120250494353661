import React, { useEffect } from "react";
import { Card, Table, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_DETAIL } from "../../graphql/query-mutation/ledger";
import InfoAction from "../utilities/infoAction";
import EnvironmentLabel from "../../graphql/client";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();

  function RowComp({ item }) {
    function readingValue(value) {
      const noUnderScore = value.split("_").join(" ");
      const noDash = noUnderScore.split("-").join(" ");
      const capitalized = noDash
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      return capitalized.join(" ");
    }

    return (
      <tr>
        <td className="text-title-dark">{readingValue(item.name)}</td>
        <td className="text-title-white">{readingValue(item.value)}</td>
      </tr>
    );
  }

  function MainComp({ data }) {
    return (
      <div>
        <Card body>
          <Row>
            <Col md="6" className={"no-gutters"}>
              <Table hover borderless responsive>
                <tbody>
                  {data.map((item, key) => (
                    <RowComp key={key} item={item} />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_DETAIL,
      options
    );
    const object = data?.account?.data;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getData} />;
    }
    if (!object) {
      return <InfoAction description={translate("No Account Information")} />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
