import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "../../index.scss";
import Button from "@material-ui/core/Button";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useMediaQuery, Link } from "@material-ui/core";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  subText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0",
    fontFamily: Constants.font.lightFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  rootToggleButton: {
    "& .MuiToggleButton-root": {
      color: Constants.fontColor.white,
      background: Constants.fontColor.darkBlack,
      fontFamily: Constants.font.regularFont,
      padding: "15px 0",
      margin: "0 7px 0 0",
      borderRadius: "5px",
      fontSize: Constants.fontSize.standard,
      textTransform: "lowercase",
      width: "150px",

      [theme.breakpoints.down("xs")]: {
        width: "66px",
        margin: "0 2px 0 0",
      },
      [theme.breakpoints.up("sm")]: {
        width: "130px",
      },
    },
    "& .Mui-selected": {
      background: Constants.fontColor.blue,
    },
    "& .Mui-selected:hover": {
      background: Constants.fontColor.blue,
    },
  },
}));

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 700,
      lg: 1280,
      xl: 1920,
    },
  },
});

const MultipleChoiceQuestionsComp = (props) => {
  const classes = useStyles();
  const {
    userChosenAnswer1,
    userChosenAnswer2,
    userChosenAnswer3,
    handleUserChosenAnswerChange1,
    handleUserChosenAnswerChange2,
    handleUserChosenAnswerChange3,
    questions,
    multipleAnswers,
    secretWordsToArray,
    setDisplayPage1,
    setDisplayPage2,
    checkIfUserChooseCorrectly,
    setActiveStep,
    setUserHaveWrittenSecretWords,
    handleClearUserAnswers,
    randomizeChoice
  } = props;

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: isXs ? "20px 20px" : "20px 30px" }}>
          <Typography className={classes.mainText}>
            Please answer the following questions correctly to confirm you have
            written down the Secret Words.
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ paddingTop: "50px" }}
          >
            <Grid item sm={12} md={4}>
              <Typography className={classes.subText}>
                What’s the #{questions[0] + 1} word of the secret words?
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} style={{ paddingTop: "0px" }}>
              <ToggleButtonGroup
                style={{ marginBottom: "15px" }}
                value={userChosenAnswer1}
                exclusive
                onChange={handleUserChosenAnswerChange1}
                className={classes.rootToggleButton}
              >
                {multipleAnswers[0]?.map((data, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      style={{ color: Constants.fontColor.white }}
                      value={data}
                    >
                      {secretWordsToArray[data]}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ paddingTop: "20px" }}
          >
            <Grid item sm={12} md={4}>
              <Typography className={classes.subText}>
                What’s the #{questions[1] + 1} word of the secret words?
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} style={{ paddingTop: "0px" }}>
              <ToggleButtonGroup
                style={{ marginBottom: "15px" }}
                value={userChosenAnswer2}
                exclusive
                onChange={handleUserChosenAnswerChange2}
                className={classes.rootToggleButton}
              >
                {multipleAnswers[1]?.map((data, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      style={{ color: Constants.fontColor.white }}
                      value={data}
                    >
                      {secretWordsToArray[data]}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ paddingTop: "20px" }}
          >
            <Grid item sm={12} md={4}>
              <Typography className={classes.subText}>
                What’s the #{questions[2] + 1} word of the secret words?
              </Typography>
            </Grid>
            <Grid item sm={12} md={8} style={{ paddingTop: "0px" }}>
              <ToggleButtonGroup
                style={{ marginBottom: "15px" }}
                value={userChosenAnswer3}
                exclusive
                onChange={handleUserChosenAnswerChange3}
                className={classes.rootToggleButton}
              >
                {multipleAnswers[2]?.map((data, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      style={{ color: Constants.fontColor.white }}
                      value={data}
                    >
                      {secretWordsToArray[data]}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
            <div style={{ textAlign: "right", marginBottom: "20px" }}>

            <Link
              onClick={() => {
                setUserHaveWrittenSecretWords(false);
                setActiveStep(1);
                handleClearUserAnswers();
                randomizeChoice();
              }}
              style={{marginRight: "10px", cursor:"pointer"}}
            >
              Let me see the secret words again
            </Link>

            {checkIfUserChooseCorrectly() && (
              <Button
                variant="contained"
                className={classes.button}
                style={{marginLeft: "10px"}}
                onClick={() => {
                  setDisplayPage1(false);
                  setDisplayPage2(true);
                  setActiveStep(0);
                }}
              >
                Next
              </Button>
            )}

            </div>
        </div>
      </Box>
    </div>
  );
};

export default MultipleChoiceQuestionsComp;
