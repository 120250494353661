import React, { useState } from "react";
import { Card } from "reactstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardLabel from "../utilities/cardLabel";
import Constants from "../../constants";
import ReactSelect from "react-select";
import TextFormField from "../textfield/textfieldWithOutline";
import Button from "../../components/button/button";

const Index = (props) => {
  
  const optionsForNetworks = Constants.servers.map((data) => ({
    value: data.capitalize(),
    label: data.capitalize(),
  }));

  const { t: translate } = useTranslation();
  const history = useHistory();
  const placeholderNetwork = translate("Select Network");
  const placeholderSearchBy = translate("Search By");
  const [selectedNetwork, setSelectedNetwork] = useState(optionsForNetworks.length == 1 ? optionsForNetworks[0].label : placeholderNetwork);
  const [selectedSearchBy, setSelectedSearchBy] = useState(placeholderSearchBy);

  const handleChangeNetwork = (e) => {
    setSelectedNetwork(e.value);
  };

  const handleChangeSearchBy = (e) => {
    setSelectedSearchBy(e.value);
  };

  const optionsForSearchBy = Constants.searchs.map((data) => ({
    value: data.capitalize(),
    label: data.capitalize(),
  }));

  const customStyles = {
    container: (defaultStyle) => ({
      ...defaultStyle,
      display: "inline-block",
      paddingBottom: "10px",
    }),

    control: (defaultStyle, state) => ({
      ...defaultStyle,
      maxWidth: "100%",
      border: 0,
      boxShadow: "none",
      padding: "11px 5px",
      backgroundColor: "#1E1E26",
      cursor: "pointer",
    }),

    option: (defaultStyle, state) => ({
      ...defaultStyle,
      color: "#000000",
      textAlign: "center",
      // Hover For Options
      backgroundColor: state.isSelected
        ? Constants.fontColor.white
        : Constants.fontColor.white,
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.07) !important",
      },
      cursor: "pointer",
    }),

    menu: (base) => ({
      ...base,
      margin: 0,
    }),
  };

  function AccountForm() {
    const formik = useFormik({
      initialValues: {
        publicKey: "",
        passPhrase: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.publicKey) {
          errors.publicKey = translate("Public Key Required");
        }
        return errors;
      },
      onSubmit: (values) => {
        const label = selectedNetwork.toLowerCase();
        const key = values.publicKey;
        history.push(`/account/${label}/${key}`);
      },
    });
    const { errors, values, handleChange, handleBlur, handleSubmit } = formik;
    return (
      <form>
        <div className="margin-bottom-16">
          <TextFormField
            id="publicKey"
            name="publicKey"
            type="text"
            placeholder={translate("Enter Account Public Key")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.publicKey}
            errText={errors.publicKey}
          />
        </div>
        <div className="margin-bottom-16">
          <TextFormField
            id="passPhrase"
            name="passPhrase"
            type="text"
            placeholder={translate("Enter Passphrase")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.passPhrase}
            errText={errors.passPhrase}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            handleClickButton={handleSubmit}
            color="primary"
            block
            text={translate("Search")}
          />
        </div>
      </form>
    );
  }

  function AssetForm() {
    const formik = useFormik({
      initialValues: {
        assetIssuingPublicKey: "",
        assetCode: "",
        passPhrase: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.assetIssuingPublicKey) {
          errors.assetIssuingPublicKey = translate(
            "Asset Issuing Public Key Required"
          );
        }
        if (!values.assetCode) {
          errors.assetCode = translate("Asset Code Required");
        }
        return errors;
      },
      onSubmit: (values) => null,
    });
    const { errors, values, handleChange, handleBlur, handleSubmit } = formik;
    return (
      <form>
        <div className="margin-bottom-16">
          <TextFormField
            id="assetIssuingPublicKey"
            name="assetIssuingPublicKey"
            type="text"
            placeholder={translate("Enter Asset Issuing Public Key")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.assetIssuingPublicKey}
            errText={errors.assetIssuingPublicKey}
          />
        </div>
        <div className="margin-bottom-16">
          <TextFormField
            id="assetCode"
            name="assetCode"
            type="text"
            placeholder={translate("Enter Asset Code")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.assetCode}
            errText={errors.assetCode}
          />
        </div>
        <div className="margin-bottom-16">
          <TextFormField
            id="passPhrase"
            name="passPhrase"
            type="text"
            placeholder={translate("Enter Passphrase")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.passPhrase}
            errText={errors.passPhrase}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            handleClickButton={handleSubmit}
            color="primary"
            block
            text={translate("Search")}
          />
        </div>
      </form>
    );
  }

  function LedgerForm() {
    const formik = useFormik({
      initialValues: {
        ledgerNumber: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.ledgerNumber) {
          errors.ledgerNumber = translate("Ledger Number Required");
        }
        return errors;
      },
      onSubmit: (values) => {
        const label = selectedNetwork.toLowerCase();
        const ledgerNumber = values.ledgerNumber;
        history.push(`/ledger/${label}/${ledgerNumber}`);
      },
    });
    const { errors, values, handleChange, handleBlur, handleSubmit } = formik;
    return (
      <form>
        <div className="margin-bottom-16">
          <TextFormField
            id="ledgerNumber"
            name="ledgerNumber"
            type="text"
            placeholder={translate("Enter Ledger Number")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.ledgerNumber}
            errText={errors.ledgerNumber}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            handleClickButton={handleSubmit}
            color="primary"
            block
            text={translate("Search")}
          />
        </div>
      </form>
    );
  }

  function TransactionForm() {
    const formik = useFormik({
      initialValues: {
        transactionHash: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.transactionHash) {
          errors.transactionHash = translate("Transaction Hash Required");
        }
        return errors;
      },
      onSubmit: (values) => {
        const label = selectedNetwork.toLowerCase();
        const transactionHash = values.transactionHash;
        history.push(`/transaction/${label}/${transactionHash}`);
      },
    });
    const { errors, values, handleChange, handleBlur, handleSubmit } = formik;
    return (
      <form>
        <div className="margin-bottom-16">
          <TextFormField
            id="transactionHash"
            name="transactionHash"
            type="text"
            placeholder={translate("Enter Transacton Hash")}
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.transactionHash}
            errText={errors.transactionHash}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            handleClickButton={handleSubmit}
            color="primary"
            block
            text={translate("Search")}
          />
        </div>
      </form>
    );
  }

  function DumbForm() {
    return (
      <div style={{ textAlign: "right" }}>
        <Button color="primary" block text={translate("Search")} />
      </div>
    );
  }

  function InputComp() {
    if (
      translate("Account") === selectedSearchBy &&
      selectedNetwork !== "Select Network"
    ) {
      return <AccountForm />;
    } else if (
      translate("Asset") === selectedSearchBy &&
      selectedNetwork !== "Select Network"
    ) {
      return <AssetForm />;
    } else if (
      translate("Ledger") === selectedSearchBy &&
      selectedNetwork !== "Select Network"
    ) {
      return <LedgerForm />;
    } else if (
      translate("Transaction") === selectedSearchBy &&
      selectedNetwork !== "Select Network"
    ) {
      return <TransactionForm />;
    } else {
      return <DumbForm />;
    }
  }

  return (
    <div className="card-v-padding-16" style={{ paddingTop: "75px" }}>
      <Card body>
        <CardLabel title={translate("Explore")} />
        <ReactSelect
          isSearchable={false}
          styles={customStyles}
          value={{
            label: selectedNetwork,
            value: selectedNetwork,
          }}
          onChange={(e) => handleChangeNetwork(e)}
          options={optionsForNetworks}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "white",
              primary50: "white",
              neutral80: "white",
            },
          })}
          components={{
            IndicatorSeparator: () => null,
          }}
        />

        <ReactSelect
          isSearchable={false}
          styles={customStyles}
          value={{
            label: selectedSearchBy,
            value: selectedSearchBy,
          }}
          onChange={(e) => handleChangeSearchBy(e)}
          options={optionsForSearchBy}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "white",
              primary50: "white",
              neutral80: "white",
            },
          })}
          components={{
            IndicatorSeparator: () => null,
          }}
        />

        <InputComp />
      </Card>
    </div>
  );
};

export default Index;
