import React, { useEffect } from "react";
import Navbar from "../../components/navigation";
import {
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Box,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/pro-light-svg-icons";
import Constants from "../../constants";
import {
  QUERY_TRANSACTION,
  QUERY_ACCOUNT_DETAIL,
} from "../../graphql/query-mutation/ledger";
import { useLazyQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import loadingGif from "../../assets/images/loading.gif";
import EnvironmentLabel from "../../graphql/client";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 0px",
  },
  container: {
    backgroundColor: "#272831",
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    padding: 32,
    "& .MuiTableCell-root": {
      borderBottomColor: Constants.fontColor.gray,
    },
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  text: {
    color: Constants.fontColor.gray,
    fontSize: "16px !important",
    wordBreak: "break-all",
  },
  title: {
    color: "#FFFFFF",
    fontSize: "18px !important",
  },
  textValue: {
    color: "#b7b5b5",
    fontSize: "16px !important",
    wordBreak: "break-all",
  },
}));

const Index = (props) => {
  const { label, hash } = useParams();

  const classes = useStyles();
  const client = EnvironmentLabel.getClient(label);

  const optionsForLedger = {
    client: client,
    fetchPolicy: "network-only",
  };

  const [
    getAccountDataDes,
    { loading: accDesLoading, data: accDesData },
  ] = useLazyQuery(QUERY_ACCOUNT_DETAIL, optionsForLedger);
  const [
    getAccountDataSource,
    { loading: accSrcLoading, data: accSrcData },
  ] = useLazyQuery(QUERY_ACCOUNT_DETAIL, optionsForLedger);

  const [
    getTransaction,
    { loading: tranLoading, data: tranData },
  ] = useLazyQuery(QUERY_TRANSACTION, optionsForLedger);

  useEffect(() => {
    getTransaction({
      variables: {
        hash: hash,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (accDesData) {
      console.log("Data2", accDesData);
    }
  }, [accDesData]);

  useEffect(() => {
    if (accSrcData) {
      console.log("Data1", accSrcData);
    }
  }, [accSrcData]);

  useEffect(() => {
    if (tranData) {
      console.log("Json", tranData);
      const pubKey = tranData?.transaction.operations.find(
        (item) => item.applicationOrder === 1
      );
      console.log("Pubkey", JSON.parse(pubKey.details).to);
      getAccountDataDes({
        variables: {
          pubKey: JSON.parse(pubKey.details).to,
          name: "registration_name",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tranData]);

  useEffect(() => {
    if (tranData) {
      console.log("Json", tranData);
      const pubKey = tranData?.transaction.operations.find(
        (item) => item.applicationOrder === 1
      );
      console.log("Pubkey2", JSON.parse(pubKey.details).from);
      const key = JSON.parse(pubKey.details).from;
      getAccountDataSource({
        variables: {
          pubKey: key,
          name: "registration_name",
        },
      });
    }
  }, [tranData]);

  const getAmount = (data) => {
    let amount = "";
    if (data) {
      amount = JSON.parse(data.details).amount;
    }
    return amount;
  };

  const getFee = (data) => {
    let amount = "";
    if (data) {
      amount = parseFloat(JSON.parse(data.details).amount) * 100;
    }
    return amount;
  };

  return (
    <div>
      <Navbar selectedTitle="dashboard" />
      <Container maxWidth="xl">
        {tranLoading && accDesLoading && accSrcLoading ? (
          <img
            alt="loading gif"
            src={loadingGif}
            style={{ display: "block", margin: "0 auto", width: "90px" }}
          />
        ) : (
          <div className={classes.content}>
            <Box className={classes.container}>
              <div className={classes.row}>
                <FontAwesomeIcon icon={faReceipt} style={{ color: "white" }} />
                <Typography
                  className={classes.title}
                  style={{ marginLeft: 16 }}
                >
                  Payment Information
                </Typography>
              </div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="custom pagination table"
                >
                  <TableBody>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>
                          Transaction Hash
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {hash}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>From</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {accSrcData?.account.data &&
                            accSrcData?.account.data.map((userInfo, index) => {
                              return (
                                userInfo.name === "registration_name" &&
                                userInfo.value
                              );
                            })}
                          &nbsp; / &nbsp;
                          {accSrcData?.account.data &&
                            accSrcData?.account.data.map((userInfo, index) => {
                              return (
                                userInfo.name === "service_name" &&
                                userInfo.value
                              );
                            })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>To</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {accDesData?.account.data &&
                            accDesData?.account.data.map((userInfo, index) => {
                              return (
                                userInfo.name === "registration_name" &&
                                userInfo.value
                              );
                            })}
                          &nbsp; / &nbsp;
                          {accDesData?.account.data &&
                            accDesData?.account.data.map((userInfo, index) => {
                              return (
                                userInfo.name === "service_name" &&
                                userInfo.value
                              );
                            })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>Amount</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {getAmount(
                            tranData?.transaction.operations.find(
                              (item) => item.applicationOrder === 1
                            )
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>Fee</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {getFee(
                            tranData?.transaction.operations.find(
                              (item) => item.applicationOrder === 2
                            )
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>Memo</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {tranData?.transaction.memo}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography className={classes.text}>Ledger</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.textValue}>
                          {tranData?.transaction.ledgerSequence}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Index;
