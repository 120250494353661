import React from "react";
import { Row, Col } from "reactstrap";
import Navbar from "../../components/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/pro-light-svg-icons";
import Container from "@material-ui/core/Container";

const Index = (props) => {
  return (
    <div>
      <Navbar selectedTitle="" />
      <Container maxWidth="xl">
        <Row className="row-h-padding row-v-padding">
          <Col md="12" className={"no-gutters text-center col-v-padding-56"}>
            <FontAwesomeIcon
              icon={faFrown}
              className="color-notfound"
              size="10x"
            />
            <div className="color-notfound text-not-found">404</div>
            <div className="color-notfound text-title-white">
              The page you are looking for does not exist or another error
              occurred.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
