import React from "react";
import EnvironmentLabel from "../../graphql/client";

const Index = ({ label = "" }) => {
  const color = EnvironmentLabel.getColor(label);

  return (
    <div
      style={{ backgroundColor: color }}
      className="ledger-label text-title-white text-capitalized"
    >
      {label}
    </div>
  );
};

export default Index;
