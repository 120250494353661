import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import TextFormField from "../../../components/textfield/textfield";
import PropTypes from "prop-types";
import MainButton from "../../../components/button/button";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#FFFFFF",
    fontSize: "16px !important",
  },
  text: {
    color: "#767676",
    fontSize: "16px !important",
  },
  gridTextCenter: {
    height: "52px",
    marginTop: "16px",
    alignItems: "center",
  },
  defaultPadding: {
    padding: "10px 15px",
  },
  border: {
    borderTop: "1px solid #1E1E26",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    padding: "10px 15px",
  },
  left: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const {
    handleManageData,
    index,
    manageData,
    handleManageDataTextChange,
  } = props;

  const handleClickOkBtn = () => {
    console.log("item", manageData);
    handleManageData(index, manageData);
  };

  return (
    <Grid container className={`${classes.defaultPadding} ${classes.border}`}>
      {manageData.map((item, objIndex) => (
        <Grid item xs={12} sm={6} key={objIndex}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              className={`${classes.gridTextCenter} ${classes.gridItem}`}
            >
              <Typography className={classes.text}>{item.text}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              lg={8}
              className={classes.gridItem}
            >
              <TextFormField
                placeholder={item.placeholder}
                name="name"
                value={item.value}
                type="text"
                disableUnderline={true}
                handleChange={handleManageDataTextChange(index, objIndex)}
                maxLength={false}
                errText={item.errMsg}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container className={`${classes.defaultPadding} ${classes.left}`}>
        <MainButton text="OK" handleClickButton={handleClickOkBtn} />
      </Grid>
    </Grid>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  manageData: PropTypes.array,
  handleManageData: PropTypes.func,
  handleManageDataTextChange: PropTypes.func,
  index: PropTypes.number,
};

export default Index;
