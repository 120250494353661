import React, { useEffect } from "react";
import { Card, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_ACCOUNT_BALANCES } from "../../graphql/query-mutation/ledger";
import InfoAction from "../utilities/infoAction";
import EnvironmentLabel from "../../graphql/client";

const Index = () => {
  const { t: translate } = useTranslation();
  const { label, key } = useParams();
  const history = useHistory();

  function HeadComp() {
    return (
      <tr>
        <th className="text-title-dark">{translate("Last Modified Ledger")}</th>
        <th className="text-title-dark">{translate("Balance")}</th>
        <th className="text-title-dark">{translate("Asset Code")}</th>
        <th className="text-title-dark">{translate("Asset Issuer")}</th>
        <th className="text-title-dark">{translate("Auth")}</th>
        <th className="text-title-dark">{translate("Limit")}</th>
        <th className="text-title-dark">{translate("Buying Liabilities")}</th>
        <th className="text-title-dark">{translate("Selling Liabilities")}</th>
      </tr>
    );
  }

  function RowComp({ item }) {
    function flagIcon() {
      if (true === item.isAuthorized) {
        return faCheckCircle;
      } else {
        return faTimesCircle;
      }
    }

    function flagColor() {
      if (true === item.isAuthorized) {
        return "color-green";
      } else {
        return "color-red";
      }
    }

    return (
      <tr>
        <td
          className="text-para-link"
          onClick={() =>
            history.push(`/ledger/${label}/${item.lastModifiedLedger}`)
          }
        >
          {item.lastModifiedLedger}
        </td>
        <td className="text-para-white">{item.balance}</td>
        <td className="text-para-white">{item.assetCode}</td>
        <th className="text-para-white">{item.assetIssuer}</th>
        <td className="text-para-white">
          <FontAwesomeIcon
            icon={flagIcon()}
            className={flagColor()}
            size="lg"
          />
        </td>
        <td className="text-para-white">{item.limit}</td>
        <td className="text-para-white">{item.buyingLiabilities}</td>
        <td className="text-para-white">{item.sellingLiabilities}</td>
      </tr>
    );
  }

  function MainComp({ data }) {
    return (
      <div>
        <Card body>
          <Table hover borderless responsive>
            <thead>
              <HeadComp />
            </thead>
            <tbody>
              {data.map((item, key) => (
                <RowComp key={key} item={item} />
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }

  function LazyComp() {
    const client = EnvironmentLabel.getClient(label);
    const options = {
      client: client,
      variables: { pubKey: key },
      fetchPolicy: "network-only",
    };
    const [getData, { loading, data, error }] = useLazyQuery(
      QUERY_ACCOUNT_BALANCES,
      options
    );
    const object = data?.account?.balances;

    useEffect(() => {
      getData();
      return () => {};
    }, [getData]);

    if (loading) {
      return <InfoAction description={translate("Loading")} />;
    }
    if (error) {
      return <InfoAction action={translate("Reload")} onClick={getData} />;
    }
    if (!object) {
      return <InfoAction description={translate("No Balance Information")} />;
    }
    return <MainComp data={object} />;
  }

  return <LazyComp />;
};

export default Index;
