import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";

const useStyles = makeStyles((theme) => ({
  rowCenter: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
  text: {},
}));

const SelectOption = (props) => {
  const classes = useStyles();
  const { options, handleChangeValue, selectedOption, index, objIndex } = props;

  const style = {
    control: (base, state) => ({
      ...base,
      border: 0,
      padding: "10px 5px",
      backgroundColor: "#1E1E26",
      boxShadow: state.isFocused ? 0 : 0,
    }),
    option: (defaultStyle, state) => ({
      ...defaultStyle,
      color: "#000000",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={faAngleDown} />
        </components.DropdownIndicator>
      )
    );
  };

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div className={classes.rowCenter}>
        <Typography>{props.data.label}</Typography>
      </div>
    </Option>
  );

  return (
    <Select
      value={{
        label: selectedOption.label,
        value: selectedOption.value,
      }}
      onChange={(e) =>
        objIndex === undefined
          ? handleChangeValue(e, index)
          : handleChangeValue(e, index, objIndex)
      }
      autoFocus={false}
      options={options}
      hideSelectedOptions={true}
      components={{
        Option: IconOption,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "white",
          primary50: "white",
          neutral80: "white",
        },
      })}
      styles={style}
    />
  );
};

SelectOption.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  handleChangeValue: PropTypes.func,
  selectedOption: PropTypes.object,
  index: PropTypes.number,
  objIndex: PropTypes.number,
};

export default SelectOption;
