import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "../../index.scss";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#75DAAD",
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.semiBoldFont,
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    fontFamily: Constants.font.regularFont,
  },
  label: {
    color: Constants.fontColor.gray,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "0px",
    fontFamily: Constants.font.semiBoldFont,
  },
  container: {
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
    "& .MuiTableCell-head": {
      color: Constants.fontColor.gray,
      paddingLeft: "15px !important",
    },
    "& .MuiTableCell-body": {
      color: Constants.fontColor.white,
      fontFamily: Constants.font.lightFont,
    },
  },
  button: {
    backgroundColor: Constants.fontColor.blue,
    color: Constants.fontColor.white,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Constants.fontColor.darkerBlue,
      boxShadow: "none",
    },
    paddingLeft: "50px",
    paddingRight: "50px",
    height: "58px",
    boxShadow: "none",
    fontFamily: Constants.font.regularFont,
    letterSpacing: "0px",
    fontSize: Constants.fontSize.standard,
  },
  lineStyle: {
    borderBottom: "1px solid #767676",
    margin: "3px 0",
    width: "100%",
  },
}));

const AccountCreatedSuccessfulComp = (props) => {
  const classes = useStyles();
  const { secretWords, publicAndSecretKeyCombined } = props;
  const history = useHistory();

  return (
    <div>
      <Box
        component="div"
        className={classes.container}
        style={{ position: "relative" }}
      >
        <div style={{ padding: "20px 30px" }}>
          <Typography className={classes.title}>
            Testing Account Successful
          </Typography>

          <hr
            style={{
              border: "1px solid #1E1F2C",
              marginTop: "-5px",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />

          <Typography
            className={classes.mainText}
            style={{ marginTop: "20px" }}
          >
            Your account has been created and funded with 200 native tokens
            successfully on SSN
          </Typography>

          <Grid
            container
            className={classes.root}
            spacing={4}
            style={{ padding: "42px 0" }}
          >
            <Grid item xs={12} sm={2} style={{ paddingTop: "10px" }}>
              <Typography
                className={classes.mainText}
                style={{ color: Constants.fontColor.gray }}
              >
                Secret Words
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
              <Typography className={classes.mainText}>
                {secretWords}
              </Typography>
            </Grid>

            <hr className={classes.lineStyle} />

            <Grid item xs={12} sm={2} style={{ paddingTop: "10px" }}>
              <Typography
                className={classes.mainText}
                style={{ color: Constants.fontColor.gray }}
              >
                Public Key
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
              <Typography
                className={classes.mainText}
                style={{ wordBreak: "break-all" }}
              >
                {publicAndSecretKeyCombined[1][1]}
              </Typography>
            </Grid>

            <hr className={classes.lineStyle} />

            <Grid item xs={12} sm={2} style={{ paddingTop: "10px" }}>
              <Typography
                className={classes.mainText}
                style={{ color: Constants.fontColor.gray }}
              >
                Secret Key
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
              <Typography
                className={classes.mainText}
                style={{ wordBreak: "break-all" }}
              >
                {publicAndSecretKeyCombined[2][1]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              className={classes.button}
              type="submit"
              onClick={() => history.push("/tools")}
            >
              Done
            </Button>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default AccountCreatedSuccessfulComp;
