import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import Constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    color: "#75DAAD",
    fontSize: Constants.fontSize.titleContainer,
    paddingBottom: "15px",
    fontFamily: Constants.font.boldFont,
    paddingTop: "24px",
  },
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "50px",
    fontFamily: Constants.font.lightFont,
  },
  dialogRoot: {
    "& .MuiPaper-root": {
      backgroundColor: Constants.fontColor.lightBlack,
      borderRadius: "8px",
      textAlign: "center",
    },
  },
}));

export default function AlertDialog(props) {
  const { open, handleClose, title, content } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogRoot}
      >
        <DialogContent>
          <div style={{ padding: "0 30px", textAlign: "center" }}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "50px", color: "#75DAAD", marginTop: "24px" }}
            />
            <Typography className={classes.mainTitle}>{title}</Typography>
            <Typography className={classes.mainText}>{content}</Typography>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
