import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigation";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";

import SelectNetworkComp from "../../components/keypair/selectNetworkComp";
import ImportXdrComp from "../../components/sign-transaction/importXdrComp";
import TransactionOverviewComp from "../../components/sign-transaction/transactionOverviewComp";
import EnterSecretKeyComp from "../../components/sign-transaction/enterSecretKeyComp";
import SignTransactionResultComp from "../../components/sign-transaction/signTransactionResultComp";
import Constants from "../../constants";

import { useForm } from "react-hook-form";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { animateScroll as scroll } from "react-scroll";
import SubmitToNetworkResult from "../../containers/sign-transaction/SubmitToNetworkResult";
import axios from "axios";

const StellarSdk = require("stellar-sdk");
const StellarWallet = require("stellar-hd-wallet");

const useStyles = makeStyles((theme) => ({
  breadcrumbMain: {
    color: Constants.fontColor.white,
    display: "inline-block",
    fontFamily: Constants.font.semiBoldFont,
  },
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      background: "none !important",
    },
    "& .MuiStepConnector-lineVertical": {
      marginLeft: "8px",
    },
    "& .MuiStepper-root": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    "& .MuiStepLabel-iconContainer": {
      color: "green",
    },
    "& .MuiStepContent-root": {
      marginLeft: "20px",
      marginTop: "-39px",
      paddingLeft: "33px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem !important",
    },
    "& .MuiStepLabel-label": {
      color: "cyan",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: Constants.fontColor.blue,
      borderRadius: "50%",
      border: "2px solid #342EAD",
    },
  },
  icon: {
    // backgroundColor: '#1e1f2c',
    borderRadius: "50%",
    border: "2px solid #6B6B6B",
    color: "#1e202c",
  },
}));

// Sign the transaction
const signTransaction = (
  XdrBase64TxString,
  signerKeypair,
  networkPassphrase
) => {
  const transaction = StellarSdk.TransactionBuilder.fromXDR(
    XdrBase64TxString,
    networkPassphrase
  );
  transaction.sign(StellarSdk.Keypair.fromSecret(signerKeypair));
  return transaction.toXDR();
};

// Get the informations from XDR
const getXdrTransactionOverview = (XdrBase64TxString, networkPassphrase) => {
  const transaction = StellarSdk.TransactionBuilder.fromXDR(
    XdrBase64TxString,
    networkPassphrase
  );
  return transaction;
};

// Get Secret key from secret word
const getKeypairCloneFromSdk = (seed, pathId) => {
  try {
    const wallet = StellarWallet.fromMnemonic(seed);
    return wallet.getKeypair(pathId);
  } catch (err) {
    console.log("get keypair err:", err.message);
    return err.message;
  }
};

const Index = () => {
  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const classes = useStyles();

  const [selectedNetwork, setselectedNetwork] = useState("Select Network");
  const handleChangeNetwork = (e) => {
    setselectedNetwork(e.value);
  };

  const [transactionOverview, setTransactionOverview] = useState();
  const [xdrFromImporting, setXdrFromImporting] = useState();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    setError: setError2,
  } = useForm();

  const onSubmit = (formData) => {
    if (formData.xdrInput) {
      try {
        Promise.resolve(
          getXdrTransactionOverview(
            formData.xdrInput,
            Constants.getNetworkPassphrase(selectedNetwork)
          )
        ).then((data) => {
          setXdrFromImporting(formData.xdrInput);
          setTransactionOverview(data);
          console.log(data);
        });
      } catch (error) {
        console.log("error:");
        setError("xdrInput", {
          type: "invalidXdr",
        });
      }
    }
  };

  const [typeOfSecret, setTypeOfSecret] = React.useState("Secret Keys");
  const handleSecretChange = (event, secret) => {
    if (secret !== null) {
      setTypeOfSecret(secret);
    }
  };

  const [xdrForViewer, setXdrForViewer] = useState();
  const [submitToNetworkResult, setSubmitToNetworkResult] = useState();

  const submitToNetwork = () => {
    setLoading(true);
    axios
      .post(`${Constants.getSsnApi(selectedNetwork)}/transactions`, {
        tx: xdrForViewer,
      })
      .then((res) => {
        scrollToBottom();
        console.log(res);
        setLoading(false);
        setSubmitToNetworkResult(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        setSubmitToNetworkResult(err?.response?.data?.extras?.result_codes);
      });
  };

  const onSubmitSignTransaction = (formData) => {
    if (typeOfSecret === "Secret Keys") {
      try {
        Promise.resolve(
          signTransaction(
            xdrFromImporting,
            formData.signerSecret,
            Constants.getNetworkPassphrase(selectedNetwork)
          )
        ).then((data) => {
          setXdrForViewer(data);
          scrollToBottom();
        });
      } catch (error) {
        console.log("error:");
        setError2("signerSecret", {
          type: "incorrect",
        });
      }
    }

    if (typeOfSecret === "Secret Words") {
      try {
        const keypair = getKeypairCloneFromSdk(formData.signerSecret, 0);
        Promise.resolve(
          signTransaction(
            xdrFromImporting,
            keypair.secret(),
            Constants.getNetworkPassphrase(selectedNetwork)
          )
        ).then((data) => {
          setXdrForViewer(data);
          scrollToBottom();
        });
      } catch (error) {
        console.log("error:");
        setError2("signerSecret", {
          type: "incorrect",
        });
      }
    }
  };

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (selectedNetwork === "Testing" || selectedNetwork === "Production") {
      setActiveStep(1);
    }
  }, [selectedNetwork]);

  return (
    <div>
      <Navbar selectedTitle="tools" />
      <Container maxWidth="xl">
        <Box component="div" style={{ marginTop: "30px" }}>
          <Typography
            className={classes.breadcrumbMain}
            style={{
              fontSize: Constants.fontSize.mainTitle,
            }}
          >
            Transaction Signer
          </Typography>
        </Box>

        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            nonLinear={true}
          >
            <Step expanded={true}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                  },
                }}
              />
              <StepContent>
                <SelectNetworkComp
                  selectedNetwork={selectedNetwork}
                  handleChangeNetwork={handleChangeNetwork}
                  label={"Select a Network"}
                  displayButton={false}
                />
              </StepContent>
            </Step>

            {selectedNetwork !== "Select Network" && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <ImportXdrComp
                    title={"Import a transaction envelope in XDR format:"}
                    label={"Transaction Envelope XDR"}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                    setActiveStep={setActiveStep}
                  />
                </StepContent>
              </Step>
            )}

            {transactionOverview && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <TransactionOverviewComp
                    title={"Transaction Overview"}
                    transactionOverview={transactionOverview}
                    displayTopData={false}
                    displaySignature={false}
                    enableMarginTop={false}
                  />
                </StepContent>
              </Step>
            )}

            {transactionOverview && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <EnterSecretKeyComp
                    handleSubmit={handleSubmit2}
                    onSubmit={onSubmitSignTransaction}
                    register={register2}
                    errors={errors2}
                    typeOfSecret={typeOfSecret}
                    setTypeOfSecret={setTypeOfSecret}
                    handleSecretChange={handleSecretChange}
                    setActiveStep={setActiveStep}
                    transactionBuilderPage={false}
                  />
                </StepContent>
              </Step>
            )}

            {xdrForViewer && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <SignTransactionResultComp
                    scrollToBottom={scrollToBottom}
                    xdrForViewer={xdrForViewer}
                    submitToNetwork={submitToNetwork}
                    loading={loading}
                    setActiveStep={setActiveStep}
                  />
                </StepContent>
              </Step>
            )}

            {submitToNetworkResult && (
              <Step expanded={true}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon,
                    },
                  }}
                />
                <StepContent>
                  <SubmitToNetworkResult
                    submitToNetworkResult={submitToNetworkResult}
                    scrollToBottom={scrollToBottom}
                  />
                </StepContent>
              </Step>
            )}
          </Stepper>
        </div>
      </Container>
    </div>
  );
};

export default Index;
