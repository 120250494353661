import React, {useState, useEffect, useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "../../index.scss";
import {
  Button as MUIButton,
  Snackbar,
  Tooltip
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Constants from "../../constants";
import Button from "../button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDownload } from "@fortawesome/pro-regular-svg-icons";
import ReactToPrint from "react-to-print";
import { faCopy, faPrint } from "@fortawesome/pro-light-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  mainText: {
    color: Constants.fontColor.white,
    fontSize: Constants.fontSize.standard,
    paddingBottom: "27px",
    fontFamily: Constants.font.boldFont,
  },
  subText: {
    color: Constants.fontColor.white,
    padding: "0 50px 0 0",
    fontFamily: Constants.font.lightFont,
    alignSelf: "center",
  },
  containerSeed: {
    // marginTop: '30px',
    backgroundColor: Constants.fontColor.lightBlack,
    borderRadius: "6px",
    boxShadow: "0px 3px 3px #00000029",
  },
  table: {
    "& .MuiTable-root": {
      borderCollapse: "separate !important",
      borderSpacing: "0 5px !important",
    },
    "& .MuiTableRow-root .MuiTableCell-root": {
      borderBottom: "none !important",
    },
    "& .MuiTableCell-root": {
      fontSize: Constants.fontSize.standard,
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  secretWordsStyle: {
    padding: "20px 30px",
    border: `1px solid ${Constants.fontColor.blue}`,
    borderRadius: "6px",
    background: "#25262F",
    userSelect: "none",
    cursor: "default",
    whiteSpace: "nowrap",
  },
}));

const SecretWordsComp = (props) => {
  const classes = useStyles();
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    makeTextFile();
  }, [props.secretWords]); // eslint-disable-line react-hooks/exhaustive-deps

  const makeTextFile = () => {
    const data = new Blob([props.secretWords], { type: "text/plain" });
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);
    setDownloadLink(window.URL.createObjectURL(data));
  };

  const componentRef = useRef();

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const handleCopied = () => {
    setState({ ...state, open: true });
  };

  const { vertical, horizontal, open } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };


  return (
    <Box
      component="div"
      className={classes.containerSeed}
      style={{ position: "relative" }}
    >
      <div style={{ padding: "20px 30px" }}>
        <Typography className={classes.mainText}>
          Make sure to write down your Secret Words on a piece of paper and keep it safe.
        </Typography>

        <hr
          style={{
            border: "1px solid #1E1F2C",
            marginTop: "-10px",
            position: "absolute",
            left: 0,
            width: "100%",
          }}
        />

        <TableContainer className={classes.table} style={{ paddingTop: "5px" }}>
          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.subText}>Secret Words</TableCell>
                <TableCell className={classes.mainText} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  
                    <Box component="div">
                      <Box 
                        component="div"
                        className={classes.secretWordsStyle}
                        style={{ 
                          fontFamily: Constants.font.regularFont, 
                          marginRight: "10px",
                          display: props.userHaveWrittenSecretWords ? "none" : "inline-block"
                        }}
                      >
                        <p ref={componentRef} style={{margin: "0", padding: "0"}}>
                          {props.secretWords}
                        </p>
                      </Box>

                      {!props.userHaveWrittenSecretWords &&
                      <span>
                        <Tooltip title="Save Secret Words">
                          <MUIButton
                            download="secret-words.txt"
                            href={downloadLink}
                            style={{minWidth: "0px"}}
                          >
                            <FontAwesomeIcon icon={faDownload} color="#FFF" style={{fontSize: "1em"}}/>
                          </MUIButton>            
                        </Tooltip>

                        <ReactToPrint
                          trigger={() => (
                            <Tooltip title="Print Secret Words">
                              <MUIButton href="#" style={{minWidth: "0px"}}>
                                <FontAwesomeIcon icon={faPrint} color="#FFF" style={{fontSize: "1em"}}/>
                              </MUIButton>
                            </Tooltip>
                          )}
                          content={() => componentRef.current}
                        />

                        <CopyToClipboard
                          text={props.secretWords}
                          options={{ asHtml: true }}
                          onCopy={() => handleCopied()}
                        >
                          <Tooltip title="Copy Secret Words">
                            <MUIButton style={{minWidth: "0px"}}>
                              <FontAwesomeIcon icon={faCopy} color="#FFF" style={{fontSize: "1em"}}/>
                            </MUIButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </span>
                    }
                  </Box>

                  <div>
                    <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      open={open}
                      autoHideDuration={3000}
                      onClose={handleClose}
                    >
                      <div style={{borderRadius: "3px", background: "black", padding: "15px", display: "flex", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faCheckCircle} color="#FFF" size="2x"/>
                        <p style={{padding:0, margin: "0 10px"}}>Secret Words copied to clipboard</p>
                      </div>
                    </Snackbar>
                  </div>

                  {props.selectedNetwork === "Production" &&
                    <span style={{display: props.userHaveWrittenSecretWords && "none"}}>
                      <Button
                        text={"I've written down the secret words"}
                        handleClickButton={() => {
                          props.handleNext(1);
                          props.setUserHaveWrittenSecretWords(true);
                        }}
                      />
                    </span>
                  }

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default SecretWordsComp;
