// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const envUrl = window.RUNTIME_NODE_ENV
  ? window.RUNTIME_NODE_ENV
  : "development";

const baseUrl = {
  testing: window.RUNTIME_GRAPHQL_URL_TESTING
    ? window.RUNTIME_GRAPHQL_URL_TESTING
    : "https://gateway.testing.ssn.digital",
  master: window.RUNTIME_GRAPHQL_URL_MASTER
    ? window.RUNTIME_GRAPHQL_URL_MASTER
    : "https://gateway.master.ssn.digital",
  production: window.RUNTIME_GRAPHQL_URL_PRODUCTION
};

const networkPassphraseProd = window.RUNTIME_NETWORK_PASSPHRASE_PRODUCTION;
const networkPassphraseMaster = window.RUNTIME_NETWORK_PASSPHRASE_MASTER
  ? window.RUNTIME_NETWORK_PASSPHRASE_MASTER
  : "ssn_master_network";
const networkPassphraseTesting = window.RUNTIME_NETWORK_PASSPHRASE_TESTING
  ? window.RUNTIME_NETWORK_PASSPHRASE_TESTING
  : "ssn_testing_network";

const restApiSsn = {
  master: window.RUNTIME_REST_API_MASTER
  ? window.RUNTIME_REST_API_MASTER
  : "https://api.master.ssn.digital/v1",
  testing: window.RUNTIME_REST_API_TESTING
    ? window.RUNTIME_REST_API_TESTING
    : "https://api.testing.ssn.digital/v1",
  production: window.RUNTIME_REST_API_PRODUCTION
};

const sourceAccount = {
  publicKey: window.RUNTIME_SOURCE_ACCOUNT_PUBLIC
    ? window.RUNTIME_SOURCE_ACCOUNT_PUBLIC
    : "GAUVJBYPWHKF3HRY7CUH3VZNCEAP7H3SCQEBML4RCTE4OBC7QET2OCBA",
  secretKey: window.RUNTIME_SOURCE_ACCOUNT_SECRET
    ? window.RUNTIME_SOURCE_ACCOUNT_SECRET
    : "SC4Z77OJCME7ALT225OTULU3YMUOHAUWD6CX2NOIOKFSIEV5U4DSCVVG",
};

const color = {
  production: "#342EAD",
  testing: "#00BCD4",
  master: "#4000d4",
};

const fontSize = {
  mainTitle: "30px",
  titleContainer: "20px",
  standard: "16px",
  small: "14px",
  extraSmall: "12px",
};

const font = {
  lightFont: "OpenSans-Light",
  regularFont: "OpenSans-Regular",
  semiBoldFont: "OpenSans-SemiBold",
  boldFont: "OpenSans-Bold",
};

const fontColor = {
  white: "#FFF",
  gray: "#767676",
  blue: "#342EAD",
  lightBlack: "#272831",
  darkBlack: "#1E1E26",
  darkerBlue: "#2c278c",
  success: "#75DAAD",
  failure: "#FE346E",
  back: "#6660C8",
};

let label = {};

if(envUrl == "production"){
  label = {production: "production", testing: "testing"};
} else if (envUrl == "master") {
  label = {master: "master"}
} else {
  label = {testing: "testing"}
}

let servers = [];

if(envUrl == "production"){
  servers = [label.testing, label.production];
} else if (envUrl == "master") {
  servers = [label.master];
} else {
  servers = [label.testing];
}

const searchs = ["Account", "Ledger", "Transaction"];

const getNetworkPassphrase = (network) => {
  if (network === "Testing") {
    return networkPassphraseTesting;
  } else if (network === "Production") {
    return networkPassphraseProd;
  } else if (network === "Master"){
    return networkPassphraseMaster;
  }
};

const getSsnApi = (network) => {
  if (network === "Testing") {
    return restApiSsn.testing;
  } else if (network === "Production") {
    return restApiSsn.production;
  }else if (network === "Master") {
    return restApiSsn.master;
  }
};

const constants = {
  envUrl,
  getNetworkPassphrase,
  getSsnApi,
  sourceAccount,
  font,
  fontColor,
  fontSize,
  color,
  label,
  servers,
  searchs,
  limitLedg: 10,
  limitTran: 30,
  baseUrl,
};

export default constants;
