import gql from "graphql-tag";

const QUERY_LATEST_LEDGERS = gql`
  query($limit: Int!) {
    ledger(limit: $limit) {
      sequence
      ledgerHash
      previousLedgerHash
      transactionCount
      operationCount
      closedAt
      createdAt
      updatedAt
      id
      importerVersion
      totalCoins
      feePool
      baseFee
      baseReserve
      maxTxSetSize
      protocolVersion
      ledgerHeader
      successfulTransactionCount
      failedTransactionCount
    }
  }
`;

const QUERY_LEDGER = gql`
  query($number: Int!) {
    ledger(number: $number) {
      sequence
      ledgerHash
      previousLedgerHash
      transactionCount
      operationCount
      closedAt
      createdAt
      updatedAt
      id
      importerVersion
      totalCoins
      feePool
      baseFee
      baseReserve
      maxTxSetSize
      protocolVersion
      ledgerHeader
      successfulTransactionCount
      failedTransactionCount
    }
  }
`;

const QUERY_TRANSACTIONS = gql`
  query($number: Int!, $limit: Int!) {
    ledger(number: $number) {
      transactions(limit: $limit) {
        transactionHash
        ledgerSequence
        applicationOrder
        account
        accountSequence
        maxFee
        operationCount
        createdAt
        updatedAt
        id
        txEnvelope
        txResult
        txMeta
        txFeeMeta
        signatures
        memoType
        memo
        timeBounds
        successful
        feeCharged
        innerTransactionHash
        feeAccount
        innerSignatures
        newMaxFee
      }
    }
  }
`;

const QUERY_TRANSACTION = gql`
  query($hash: String!) {
    transaction(hash: $hash) {
      transactionHash
      ledgerSequence
      applicationOrder
      account
      accountSequence
      maxFee
      operationCount
      createdAt
      updatedAt
      id
      txEnvelope
      txResult
      txMeta
      txFeeMeta
      signatures
      memoType
      memo
      timeBounds
      successful
      feeCharged
      innerTransactionHash
      feeAccount
      innerSignatures
      newMaxFee
      operations {
        transactionID
        applicationOrder
        type
        details
        sourceAccount
      }
    }
  }
`;

const QUERY_ACCOUNT_INFO = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      sequence
      homeDomain
      nativeBalance
      masterWeight
      lowThreshold
      mediumThreshold
      highThreshold
    }
  }
`;

const QUERY_ACCOUNT_FLAGS = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      flags {
        authRequired
        authRevocable
        authImmutable
      }
    }
  }
`;

const QUERY_ACCOUNT_BALANCES = gql`
  query accountInfo($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      balances {
        balance
        buyingLiabilities
        sellingLiabilities
        limit
        lastModifiedLedger
        isAuthorized
        assetCode
        assetIssuer
      }
    }
  }
`;

const QUERY_ACCOUNT_SIGNERS = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      signers {
        weight
        key
      }
    }
  }
`;

const QUERY_ACCOUNT_TRANSACTIONS = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      transactions {
        transactionHash
        ledgerSequence
        applicationOrder
        account
        accountSequence
        maxFee
        operationCount
        createdAt
        updatedAt
        id
        txEnvelope
        txResult
        txMeta
        txFeeMeta
        signatures
        memoType
        memo
        timeBounds
        successful
        feeCharged
        innerTransactionHash
        feeAccount
        innerSignatures
        newMaxFee
        operations {
          id
          transactionID
          applicationOrder
          type
          details
          sourceAccount
        }
      }
    }
  }
`;

const QUERY_ACCOUNT_DETAIL = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      data {
        name
        value
      }
    }
  }
`;

const QUERY_ACCOUNT_REGISTRATION_NAME = gql`
  query($pubKey: String!) {
    account(pubKey: $pubKey) {
      id
      data(name: "registration_name") {
        value
      }
    }
  }
`;

const QUERY_ACCOUNT_DATA = gql`
  query($pubKey: String!, $name: String) {
    account(pubKey: $pubKey) {
      id
      data(name: $name) {
        value
      }
    }
  }
`;

export {
  QUERY_ACCOUNT_REGISTRATION_NAME,
  QUERY_LATEST_LEDGERS,
  QUERY_LEDGER,
  QUERY_TRANSACTIONS,
  QUERY_TRANSACTION,
  QUERY_ACCOUNT_INFO,
  QUERY_ACCOUNT_FLAGS,
  QUERY_ACCOUNT_BALANCES,
  QUERY_ACCOUNT_SIGNERS,
  QUERY_ACCOUNT_TRANSACTIONS,
  QUERY_ACCOUNT_DETAIL,
  QUERY_ACCOUNT_DATA,
};
